  
  export default function LandingPage() {
    return (
        <>
      <div className="titlepic relative isolate overflow-hidden bg-gray-900 py-24 sm:py-32">
        <img
          src="./Stadtwerkstatt/Stadtwerkstatt_Team.jpeg"
          alt=""
          className="absolute inset-0 -z-10  w-full object-cover object-center md:object-center"
        />
        <div
  className="hidden sm:absolute sm:-top-10 sm:right-1/2 sm:-z-10 sm:mr-10 sm:block sm:transform-gpu sm:blur-3xl"
  aria-hidden="true"
>
  <div
    className="aspect-[1097/845] w-[68.5625rem] bg-gradient-to-tr from-[#3c9d7a] to-[#1b5b4a] opacity-50"
    style={{
      clipPath:
        'polygon(74.1% 44.1%, 100% 61.6%, 97.5% 26.9%, 85.5% 0.1%, 80.7% 2%, 72.5% 32.5%, 60.2% 62.4%, 52.4% 68.1%, 47.5% 58.3%, 45.2% 34.5%, 27.5% 76.7%, 0.1% 64.9%, 17.9% 100%, 27.6% 76.8%, 76.1% 97.7%, 74.1% 44.1%)',
    }}
  />
</div>
<div
  className="absolute -top-52 left-1/2 -z-10 -translate-x-1/2 transform-gpu blur-3xl sm:top-[-28rem] sm:ml-16 sm:translate-x-0 sm:transform-gpu"
  aria-hidden="true"
>
  <div
    className="aspect-[1097/845] w-[68.5625rem] bg-gradient-to-tr from-[#3c9d7a] to-[#1b5b4a] opacity-30"
    style={{
      clipPath:
        'polygon(74.1% 44.1%, 100% 61.6%, 97.5% 26.9%, 85.5% 0.1%, 80.7% 2%, 72.5% 32.5%, 60.2% 62.4%, 52.4% 68.1%, 47.5% 58.3%, 45.2% 34.5%, 27.5% 76.7%, 0.1% 64.9%, 17.9% 100%, 27.6% 76.8%, 76.1% 97.7%, 74.1% 44.1%)',
    }}
  />
</div>
<div
  className="absolute -top-52 left-1/2 -z-10 -translate-x-1/4 transform-gpu blur-3xl sm:top-[-28rem] sm:ml-16 sm:translate-x-0 sm:transform-gpu"
  aria-hidden="true"
>
  <div
    className="aspect-[1097/845] w-[68.5625rem] bg-gradient-to-tr from-[#3c9d7a] to-[#1b5b4a] opacity-30"
    style={{
      clipPath:
        'polygon(74.1% 44.1%, 100% 61.6%, 97.5% 26.9%, 85.5% 0.1%, 80.7% 2%, 72.5% 32.5%, 60.2% 62.4%, 52.4% 68.1%, 47.5% 58.3%, 45.2% 34.5%, 27.5% 76.7%, 0.1% 64.9%, 17.9% 100%, 27.6% 76.8%, 76.1% 97.7%, 74.1% 44.1%)',
    }}
  />
</div>
              
     
        <div className="mx-auto max-w-7xl px-6 lg:px-8">
          <div className="text1 mx-auto max-w-2xl lg:mx-0">
            <h1 className="text-4xl text1 font-bold tracking-tight text-white sm:text-6xl" style={{textShadow: "1px 1px 5px rgba(0, 0, 0,100),2px 2px 10px rgba(0, 0, 0, 1)" }}>Die StadtWerkstatt</h1>
            <p  className="mt-6 text1 text-lg leading-8 text-gray-50" style={{ textShadow: "1px 1px 5px rgba(0, 0, 0, 1), 2px 2px 10px rgba(0, 0, 0, 1), 3px 3px 15px rgba(0, 0, 0, 1),2px 2px 10px rgba(0, 0, 0, 1)" }}>
              Eine regionale Plattform, die Kunst und Handwerk vereint.
            </p>
          </div>
          
          
        </div>
        
      </div>

      </>
    )
  }
  