
import { useNavigate } from "react-router-dom";

export default function Cardexpander() {
    const navigate = useNavigate();
return(
    <>
    <div className="expander" >
     <div class="containerexpander">
        <div class="card" >
            <div class="face face1">
                <div class="content">
                    <img src="./Stadtwerkstatt/Stadtwerkstatt_Anblick_Aussen.jpg" alt="code"/>
                    <h3>Geschäft</h3>
                </div>
            </div>
            <div class="face face2">
                <div class="content">
                    <p>
                    Die Stadtwerkstatt Bautzen, ansässig in der Steinstraße 15 in Bautzen, dient als kreatives Zentrum, in dem Kunsthandwerker und Künstler ihre Werke und Workshops anbieten. 
                    </p>
                    <a href="https://www.google.de/search?sa=X&sca_esv=7a12701d0fbd3cd4&sca_upv=1&hl=de-DE&tbm=lcl&sxsrf=ACQVn0_T2YoGbhnz8IdwSDMcExDBvcgUqQ:1707854177754&q=StadtWerkstatt+Bautzen+Rezensionen&rflfq=1&num=20&stick=H4sIAAAAAAAAAONgkxIxNLKwNDG1MDE1NLc0NzE0NDM0M9jAyPiKUSm4JDGlJDy1KLu4JLGkRMEpsbSkKjVPISgVSBZn5uel5i1iJUIRABe3etRlAAAA&rldimm=12894584517974116160&ved=2ahUKEwiM8t7MjKmEAxXfh_0HHU_3CscQ9fQKegQIFRAF&biw=1920&bih=919&dpr=1#lkt=LocalPoiReviews">Standort</a>
                </div>
            </div>
        </div>

        <div class="card" >
            <div class="face face1">
                <div class="content">
                    <img src="./Stadtwerkstatt/Gruenderin_Stadtwerkstatt_Kati_Schmidt.JPG" alt="design"/>
                    <h3>Gründerin</h3>
                </div>
            </div>
            <div class="face face2">
                <div class="content">
                    <p>
                    Die Gründerin, Kati Schmidt, ist bekannt für ihre Kunstwerke unter dem Label „Ei-der-Daus“. Ihre Leidenschaft hat sie erfolgreich in die Eröffnung dieses kreativen Raums eingebracht.
                    </p>
                    <a onClick={(e)=>navigate('/team')}>Mehr Infos</a>
                </div>
            </div>
        </div>

        <div class="card " >
            <div class="face face1">
                <div class="content">
                    <img src="./Stadtwerkstatt/Workshopraum_Stadtwerkstatt.jpg" alt="launch"/>
                    <h3>Workshops</h3>
                </div>
            </div>
            <div class="face face2">
                <div class="content">
                    <p>
                    In den Workshops der Stadtwerkstatt Bautzen können Teilnehmer vielfältige handwerkliche und künstlerische Fertigkeiten unter der Anleitung erfahrener Kreativer entdecken und vertiefen.
                    </p>
                    <a onClick={(e)=>navigate('/workshops')}>Mehr Infos</a>
                </div>
            </div>
        </div>
    </div>
    </div>
    </>
)
}