const TeamImpressumData = [
    {   index:0,
        id: 'Kati',
        name: "Kati Schmidt",
        description: 'Als Gründerin von "Ei-der-Daus" verwandle ich Eierschalen mit präziser Technik in filigrane Kunstwerke und teile mein Können in Workshops, während ich meinen Traum einer Stadtwerkstatt in Bautzen lebe.',
        backgroundImage: process.env.REACT_APP_PUBLIC_URL +'/Kati_Schmidt/Ei-der-Daus-Eierfräsen.PNG',
        portrait: process.env.REACT_APP_PUBLIC_URL +'/Kati_Schmidt/Portrait_Kati_Schmidt.jpeg',
        images: [process.env.REACT_APP_PUBLIC_URL +"/Kati_Schmidt/Ei_der_Daus_Käfigei.jpeg",
        process.env.REACT_APP_PUBLIC_URL +"/Kati_Schmidt/Ei_der_Daus_Ei.jpg",
        process.env.REACT_APP_PUBLIC_URL +"/Kati_Schmidt/Kati_Schmidt_Stadtwerkstadt.JPG",
        process.env.REACT_APP_PUBLIC_URL +"/Kati_Schmidt/Ei-der-Daus-Geschenk.PNG",
        process.env.REACT_APP_PUBLIC_URL +"/Kati_Schmidt/Ei_der_Daus_Verkauf.jpeg",
        process.env.REACT_APP_PUBLIC_URL +"/Kati_Schmidt/Ei_der_Daus_Eierkunst.PNG"],
        socialLinks: {
          instagram: "https://www.instagram.com/ei__der_daus/",
          facebook: "https://www.facebook.com/profile.php?id=100073333073365&ref=xav_ig_profile_web",
          website: "https://stadtwerkstatt-bautzen.de",
        },
      leader:'Inhaberin von Ei-der-Daus und der StadtWerkstadt',
      address:'Blumenstraße 31, 02692 Doberschau',
      email: 'meinert.kati@freenet.de',
      mobile: '+49 176 50494712',
      fon:'+49 3591 272668',
      vatID:'204 269 02711',
      descriptionInfo: 'Kleinunternehmerin iSv § 19 UStG',
      detailedExpo:`Ich bin Kati Schmidt, eine gelernte Zahnarzthelferin und Prophylaxe-Assistentin, die ihre Leidenschaft für Kunst in der wunderschönen Stadt Bautzen auslebt. Neben meiner Arbeit in einer Zahnarztpraxis widme ich mich der Kunst, insbesondere der Verwandlung von einfachen Eiern in kleine, filigrane Kunstwerke unter meinem Label „Ei-der-Daus“. Vor etwa acht Jahren begann ich, mit einem tragbaren Zahnarztgerät Muster in die zerbrechlichen Schalen zu fräsen, ein Unterfangen, das anfangs mehr Bruch als Kunst produzierte. Doch ich gab nicht auf, verfeinerte meine Technik und entdeckte, dass Eier von Bio-Hühnern mit ihren dickeren Schalen ideal für meine Arbeit sind. Heute erinnern meine Kreationen an filigrane Lochspitze und sind auf Handwerkermärkten sehr gefragt. Ich gebe mein Wissen auch in Workshops weiter, um andere in dieser einzigartigen Kunstform zu unterrichten.

      
      <f>Mein Traum</f> war es immer, einen Raum zu schaffen, in dem Kunst und Handwerk aufeinandertreffen und sich gegenseitig bereichern. Diesen Traum habe ich mit der Eröffnung meiner Stadtwerkstatt in Bautzen verwirklicht. Nach einem Fahrradunfall, bei dem ich mir das Schlüsselbein zertrümmerte, wurde mir klar, dass ich meine Träume nicht aufschieben sollte. Mit harter Arbeit und Entschlossenheit habe ich die ehemaligen Büroräume in der Steinstraße 15 in einen Ort voller Kreativität und Inspiration verwandelt. Die Wände strahlen in Dunkelgrün und warmem Grau, und der Raum ist gefüllt mit Kunst und Handwerksarbeiten, nicht nur von mir, sondern auch von anderen talentierten Kunsthandwerkern aus der Region.
      
      <f>Die Stadtwerkstatt</f> ist ein kollektiver Raum, den ich mit sieben anderen Kunsthandwerkern teile. Wir bieten nicht nur unsere Waren zum Verkauf an, sondern auch Workshops und Veranstaltungen, um die Gemeinschaft zu fördern und unser Handwerk zu teilen. Von Fadenkunst über Malerei bis hin zu selbst genähter Kinderkleidung und Dekorationen – unsere Vielfalt ist unser Stärke. Ich bin stolz darauf, dass ich dieses Projekt vollständig aus eigener Tasche finanziert habe, getreu meinem Motto: Geht nicht, gibt es nicht.
    
      <f>Neben meiner Arbeit </f> in der Werkstatt und in der Zahnarztpraxis träume ich davon, mit meinem Rennrad von Bautzen nach Paris zu fahren. Doch bis es soweit ist, konzentriere ich mich auf den letzten Feinschliff für die Stadtwerkstatt und darauf, meine Liebe zur Kunst mit anderen zu teilen. Jeden Donnerstag öffne ich meine Türen für alle, die mir über die Schulter schauen und sehen möchten, wie aus einem einfachen Ei ein Kunstwerk entsteht. Es ist mir eine Freude, diese einzigartige Form der Kunst zu teilen und anderen die Möglichkeit zu geben, ihr eigenes Unikat in einem unserer Workshops zu erschaffen.`,
      paypal:false,
    },

    {   index:3,
        id: 'Thomas',
        name: "Thomas Liebig",
        description: 'Ich bin Thomas Liebig, ein Künstler, inspiriert von der Natur meiner Heimat Oberlausitz.',
        backgroundImage: process.env.REACT_APP_PUBLIC_URL +'/Thomas_Liebig/Thomas_Liebig_Bild.jpg',
        portrait: process.env.REACT_APP_PUBLIC_URL +'/Thomas_Liebig/Portrait_Thomas_Liebig.JPEG',
        images: [
          process.env.REACT_APP_PUBLIC_URL +"/Thomas_Liebig/Thomas_Liebig_Bild_Bautzen.PNG",
          process.env.REACT_APP_PUBLIC_URL +"/Thomas_Liebig/Thomas_Liebig_Stadtwerkstadt.jpeg",
          process.env.REACT_APP_PUBLIC_URL +"/Thomas_Liebig/Thomas_Liebig_Kunst.jpeg",
          process.env.REACT_APP_PUBLIC_URL +"/Thomas_Liebig/Thomas_Liebig_Eule.jpeg",
          process.env.REACT_APP_PUBLIC_URL +"/Thomas_Liebig/Thomas_Liebig_Karte.jpeg",
          process.env.REACT_APP_PUBLIC_URL +'/Thomas_Liebig/Thomas_Liebig_Bild.jpg'],
        socialLinks: {
          instagram: "https://www.instagram.com/liebig.thomas/",
          facebook: "https://www.facebook.com/thomas.liebig.777",
          website: "http://www.gestaltungstechnik-liebig.de/",
        },
        leader:'Inhaber von Gestaltungstechnik-Liebig',
        address:'Am Wassertrog 4, 02625 Bautzen',
        email: 'info@gestaltungstechnik-liebig.de',
        mobile: '+49 172 9719016',
        vatID:'204 245 01442',
        descriptionInfo: 'Kleinunternehmer iSv § 19 UStG',
        detailedExpo:`Die Malerei habe ich schon als Kind von meinem Großvater mitbekommen, ich liebte es ihn beim Malen von Vögeln, Katzen, Fuchs und Tauben zuzusehen. Ich besuchte in der Schulzeit aktiv den Zeichenzirkel wo ich die ersten Techniken erlernte. Später erlernte ich in der Volkshochschule weitere Maltechniken. Da mir das nicht ausreichte und ich unbedingt noch mehr über Malerei, Airbrush, Graffiti und Drucktechniken erlernen wollte nahm ich Unterricht bei Heike Dittrich in Bautzen für mehrere Jahre. Die Ölmalerei, Acryl und Aktmalerei interessierte mich. Später Besuchte ich in Dresden Kurse der Spachteltechnik bei Heidi Gerstner. Ich probierte viel im Eigenstudium und  besuchte viele Künstler um mehr wissen zu erlangen. Besondere Künstler wie Jahan Christian Dahl, Caspar David Friedrich, Elisabeth von Eicken, Otto Dix, Lea und Hans Grundig, Isaak Lewitan und andere genossen meine besondere Aufmerksamkeit.
        
        Ich bin immer begeistert Eindrücke und Momente einzufangen und diese in einem Bild festzuhalten. Für mich ist die Heimat sehr wichtig und deshalb sind viele Motive aus der Lausitz. Aber auch Italien oder die Ostsee sind ein gutes Bild wert. Oft verarbeite ich auch meine Träume und Wünsche, verarbeite meine Sorgen und mein Glück in den Bildern. Ich möchte gerne bleibende Lichtpunkte dieser Zeit setzen und für die Nachwelt erhalten. Auch interressiert mich sehr was die Menschen in meinen Bildern sehen!
        Ich möchte hier Kurse in Aquarell, Spachteltechnik, Acryl und Öl anbieten für Kinder in den Ferien, Erwachsene, Senioren, Kindergeburtstage, Vereinstreffen oder Firmenworkshops. Es soll ein Einblick geben wie leicht es ist Kunst selbst zu erleben und zu schaffen. Neue Techniken zu probieren, und vielleicht auch den einen oder anderen in ein neues Hobby einzuführen.
        
        <f>Ausstellungen:</f> Cham Schweiz, Vaduz Liechtenstein, Baden-Baden, Colmar Frankreich, Straßburg Frankreich, Brüssel Belgien, Oberbaumbrücke Berlin, Alte Münze Berlin, Weißenberg, Bautzen, Hochkirch und Kamenz
        `,
        paypal:false,
    },

    {index:9,
        id: 'Anke & Katrin',
        name: "Katrin Bandke",
        description: 'Wir sind, Katrin und Anke, aus Greiz und Dresden. Uns verbindet eine tiefe Freundschaft und die Begeisterung für alles Alte.',
        backgroundImage: process.env.REACT_APP_PUBLIC_URL +'/AnkeSeidel_KatrinBandke/VomTischZurTafel.jpg',
        portrait: process.env.REACT_APP_PUBLIC_URL +'/AnkeSeidel_KatrinBandke/Portrait_AnkeSeidel_KatrinBandke.jpeg',
        images: [process.env.REACT_APP_PUBLIC_URL +"/AnkeSeidel_KatrinBandke/Etagere_VomTischZurTafel_klein.jpg",
        process.env.REACT_APP_PUBLIC_URL +"/AnkeSeidel_KatrinBandke/VomTischZurTafel_inStadtwerkstatt.jpeg",
        process.env.REACT_APP_PUBLIC_URL +"/AnkeSeidel_KatrinBandke/Stadtwerkstatt_VomTischZurTafel.jpeg",
        process.env.REACT_APP_PUBLIC_URL +"/AnkeSeidel_KatrinBandke/Etagere_VomTischZurTafel.JPEG",
        process.env.REACT_APP_PUBLIC_URL +"/AnkeSeidel_KatrinBandke/Ohrringe_VomTischZurTafel.jpeg",
        process.env.REACT_APP_PUBLIC_URL +'/AnkeSeidel_KatrinBandke/VomTischZurTafel.jpg'],
        socialLinks: {
          instagram: "https://www.instagram.com/vomtischzurtafel/",
          facebook: "https://www.facebook.com/vomtischzurtafel.de",
          website: "https://vomtischzurtafel.de/",
        },
        leader:'Inhaberin von VomTischzurTafel',
        address:'Ortsstrasse 45, 07987 Mohlsdorf-Teichwolframsdorf ',
        email: 'Kontakt@vomtischzurtafel.de',
        mobile: 'Katrin: +49 179 3471433, Anke: +49 179 3471433',
        vatID:'166 299 24314',
        descriptionInfo: 'Kleinunternehmer/in iSv § 19 UStG',
        detailedExpo:`Wir sind Katrin und Anke.
        Wir teilen eine jahrelange Freundschaft und die Liebe zu Sammeltassen.
        Gemeinsam aufgewachsen in Greiz verbinden uns Kreativität und Leidenschaft.
        Katrin blieb im schönen Vogtland, arbeitete viele Jahre als Krankenschwester auf Kinder- und Entbindungsstationen und entschied sich 2023 für die hauptberufliche Mitwirkung im familiengeführten Landwirtschaftsbetrieb. Dort baute sie unter anderem einen Hofladen auf und beschäftigte sich intensiv mit der Eierproduktion in Mobilställen. Artgerechte Haltung, regionale Produktion und gesunde Produkte - dafür stehen ihre “Gottesgrüner Wiesenhühner”. 
        Anke verschlug es nach Dresden, wo sie als Lehrerin an einem Förderzentrum für Hörgeschädigte tätig ist.   
        2021 stattete Anke die Scheunenhochzeit eines befreundeten Paares stilsicher mit Sammeltassen aus. Diese allzu oft auf Dachböden, in Vitrinen und Truhen verborgenen und mit Geschichten besetzten Schätze stellen wir seit 2022 über unseren Verleih für eure Feste und Feiern zur Verfügung. “VomTischzurTafel” ist im Raum Dresden und dem Vogtland für euch da.
        Daneben fertigen wir Etageren und Schmuck aus Gedecken, Einzelteilen und Scherben, die ihr in der Stadtwerkstatt erwerben könnt. Und auch eure Sammeltassen verwandeln wir gern in einen echten Blickfang auf eurem Tisch. Oder soll es doch lieber ein Schmuckstück sein? 
        
        Verzaubert eure Gäste mit nostalgischen Tischgedecken und Etageren!

        “VomTischzurTafel” - Jedes Gedeck so einzigartig wie der Moment.
        
        `,
        paypal:false,
    },
    /*
    {index:12,
        id: 'Martin',
        name: "Martin Wagner",
        description: 'Ich bin Martin Wagner von der Sächsischen Spirituosenmanufaktur, wo wir aus regionalen Zutaten edle Liköre, Brände und Geiste herstellen.',
        backgroundImage: process.env.REACT_APP_PUBLIC_URL +'/Martin_Wagner/Sortiment_Martin_Wagner.jpg',
        portrait: process.env.REACT_APP_PUBLIC_URL +'/Martin_Wagner/Portrait_Martin_Wagner.PNG',
        images: [ process.env.REACT_APP_PUBLIC_URL +"/Martin_Wagner/Martin_Wagner_Stadtwerkstatt.jpeg",
        process.env.REACT_APP_PUBLIC_URL +"/Martin_Wagner/Auswahl_Spirituosenmanufaktur.jpg",
        process.env.REACT_APP_PUBLIC_URL +"/Martin_Wagner/Martin_Wagner_Ausschank.jpg",
        process.env.REACT_APP_PUBLIC_URL +"/Martin_Wagner/Auswahl_Martin_Wagner.jpeg",
        process.env.REACT_APP_PUBLIC_URL +"/Martin_Wagner/Saechsische_SpirituosenManufaktur.jpeg",
        process.env.REACT_APP_PUBLIC_URL +'/Martin_Wagner/Sortiment_Martin_Wagner.jpg'],
        socialLinks: {
          instagram: "https://www.instagram.com/saechsische.spirituosen/",
          facebook: "https://www.facebook.com/SaechsischeSpirituosenmanufaktur/",
          website: "https://www.saechsische-spirituosenmanufaktur.de/",
        },
        leader:'Inhaber von der Sächsische Spirituosenmanufaktur',
        address:'Waldstraße 30, 02681 Schirgiswalde-Kirschau',
        email: 'post@saechsische-spirituosenmanufaktur.de',
        fon: '+49 3592 3559630',
        descriptionInfo: 'Umsatzsteuer-Identifikationsnummer gemäß § 27 a Umsatzsteuergesetz: DE815569151',
        detailedExpo:`Bei uns wird man keine Massenware finden, denn wir produzieren ausschließlich kleine Mengen hochwertigster Spirituosen getreu dem Motto Qualität vor Quantität.

        In unserer Sächsischen Spirituosenmanufaktur wollen wir Genuss sowie Freude mit unseren hochwertigen, ausgefallenen und seltenen Spirituosen verbreiten.
        Ich lade euch ein, in die Welt der Liköre, Brände und Geiste aus regionalen Früchten, Blüten, Kräutern und Gewürzen.

Kommt in die StadtWERKSTATT und probiert das ein oder andere ….
Zusätzlich biete ich euch individuelle Tasting-Abende in der StadtWERKSTATT an, die ihr demnächst im Veranstaltungskalender findet.

`,
paypal:false,
    },*//*
    {index:12,
      id: 'Steffi',
      name: "Stefanie Schreiber",
      description: 'Ich bin Steffi, Gründerin von "Piepston & Lichterzauber", und kreiere in Doberschau einzigartige Keramik aus Ton.',
      backgroundImage: process.env.REACT_APP_PUBLIC_URL +'/Stefanie_Schreiber/Töpferware_Kerze.jpeg',
      portrait: process.env.REACT_APP_PUBLIC_URL +'/Stefanie_Schreiber/Portrait_Stefanie_Schreiber.jpeg',
      images: [ process.env.REACT_APP_PUBLIC_URL +"/Stefanie_Schreiber/Töpferware_Teller.jpeg",
      process.env.REACT_APP_PUBLIC_URL +"/Stefanie_Schreiber/Töpferware_Kerze.jpeg",
      process.env.REACT_APP_PUBLIC_URL +"/Stefanie_Schreiber/Töpferware_Figuren.jpeg",
      process.env.REACT_APP_PUBLIC_URL +"/Stefanie_Schreiber/Töpferware_Haus.jpeg",
      process.env.REACT_APP_PUBLIC_URL +"/Stefanie_Schreiber/Töpferware_Rosenmuster.jpeg",
      process.env.REACT_APP_PUBLIC_URL +'/Stefanie_Schreiber/Töpferware_bunt.jpeg'],
      socialLinks: {
        instagram: "https://www.instagram.com/piepston.lichterzauber/",
        
      },
      leader:'Inhaber von Piepston & Lichterzauber',
      address:'Friedensweg 1b, 02692 Doberschau',
      email: 'piepston.lichterzauber@web.de',
      fon: '+49 174 4335449',
      vatID:'3204 271 03030',
      descriptionInfo: 'Kleinunternehmer/in iSv § 19 UStG',
      detailedExpo:`Ich bin Steffi und Gründerin von Piepston & Lichterzauber. In meiner kleinen Werkstatt modellieren ich aus Ton individuelle Keramik und kann dabei meiner Kreativität den nötigen Raum geben. Es kann heilsam sein, Etwas mit seinen eigenen Händen zu schaffen und Euch damit ein Lächeln zu schenken.
     
      Ich lebe mit meiner Familie im beschaulichen Doberschau. Schon als Kind war ich sehr kreativ, habe mich in Vielem ausprobiert. Die Leidenschaft zum Töpfern hat mich aber erst von knapp 2 Jahren gepackt. Aus den ersten Arbeiten ist schnell der Wunsch nach einer Werkstatt und einem eigenen Ofen gewachsen und so haben wir im ehemaligen Kinderzimmer eine Werkstatt mit eigenem Ofen eingerichtet. Das Medium Ton ist so vielfältig, das sobald die Hände im Ton stecken, die Ideen nur so sprudeln. Ein fantastischer Ausgleich...


In der StadtWerkstatt findet Ihr zukünftig individuelle Keramik zum Mitnehmen, allerdings freue mich auch über Anfragen zu besonderen Anlässen... Dann bitte einfach Kontakt zu mir aufnehmen. 


`,
paypal:false,
  },*/
   
    {index:12,
      id: 'Sarah',
      name: "Sarah Wićaz",
      description: 'Ich bin Sarah Wićaz, Illustratorin und kreative Abenteurerin mit Liebe zu Natur und Sport.',
      backgroundImage: process.env.REACT_APP_PUBLIC_URL +'/Sarah_Wicaz/Illustrierung_Segelboot.jpeg',
      portrait: process.env.REACT_APP_PUBLIC_URL +'/Sarah_Wicaz/Portrait_Sarah_Wicaz.jpeg',
      images: [ process.env.REACT_APP_PUBLIC_URL +"/Sarah_Wicaz/Illustrierung_Strand.jpeg",
      process.env.REACT_APP_PUBLIC_URL +"/Sarah_Wicaz/Illustrierung_Kueche.jpeg",
      process.env.REACT_APP_PUBLIC_URL +"/Sarah_Wicaz/Illustrierung_Segelboot.jpeg",
      process.env.REACT_APP_PUBLIC_URL +"/Sarah_Wicaz/Illustrierung_Herz.jpeg",
      process.env.REACT_APP_PUBLIC_URL +"/Sarah_Wicaz/Fussball_Illustrierung.jpeg",
      process.env.REACT_APP_PUBLIC_URL +"/Sarah_Wicaz/Illustration_Schulkind.jpeg"],
      socialLinks: {
        instagram: "https://www.instagram.com/sawicaz?igsh=anlwMGtudzl2cmli&utm_source=qr",
       
      },
      leader:'',
      address:'Kurt-Pchalek-Str. 1, 02625 Bautzen',
      email: 'sarahwicaz@gmail.com',
      mobile:'+49 172 2602566',
      vatID:'204 287 06189',
      descriptionInfo: 'Kleinunternehmer/in iSv § 19 UStG',
      detailedExpo:`Ich bin Sarah, Erzieherin und Illustratorin. Durch meine täglichen kleinen und großen
      Abenteuer, Ausflüge, Reisen und Wandertouren werde ich zu meinen Werken inspiriert.
      Quellen dieser Inspiration sind vor allem das Wasser, der Wald und alte Häuser. In einem
      Moment des kreativen Flows mit passender akustischer Hintergrundkulisse, entfalten sich
      gestalterische Kräfte, die ich auf Papier festhalte. Das Format der Postkarte stellte sich als
      reisetauglich heraus, sodass ich auch unterwegs, on the road, vor Ort kreativ werden kann.
      Diese Originale und Prints in verschiedenen Größen könnt ihr nun hier in der Stadtwerkstatt
      erwerben.

      
      Mit der Stadtwerkstatt habe ich einen Ort gefunden, an dem ich meine Werke der
      Öffentlichkeit präsentieren und zugänglich machen kann. So ein Ort schwebte mir schon
      lange vor und bei einem Gang in die Stadt lief ich am Laden vorbei. Neugierig betrat ich die
      Location, kam ins Gespräch und bin nun Teil eines tollen Projektes, welches Künstler und
      Macher der Region verbindet.
      Studiert habe ich Kunst und Englisch in Dresden, meine Examensausstellung präsentierte
      ich im Steinhaus in Bautzen, eine weitere folgte im Grünen Laden in Bautzen. Meine
      künstlerischen Fähigkeiten habe ich bei Gerhard Nath und Heike Dittrich im Steinhaus
      Bautzen schulen können, später wurde ich mit Stoffen nach Anleitung und Inspiration durch
      Petra Hörenz-Freiberg kreativ.
      Heute fasziniert mich die Aquarellmalerei mit ihren lebendigen und zufälligen Eigenschaften.`,
      paypal:false,
  },

{index:15,
  id: 'Simone',
  name: "Simone Wujanz",
  description: 'Ich bin Simone Wujanz von Farbrausch Oberlausitz und lasse aus meiner Leidenschaft für Farben und Design einzigartigen Schmuck entstehen.',
  backgroundImage: process.env.REACT_APP_PUBLIC_URL +'/Simone_Wujanz/Simone_Wujanz_Armband.jpeg',
  portrait: process.env.REACT_APP_PUBLIC_URL +'/Simone_Wujanz/Portrait_Simone_Wujanz.jpeg',
  images: [ process.env.REACT_APP_PUBLIC_URL +"/Simone_Wujanz/Simone_Wujanz_Armband.jpeg",
  process.env.REACT_APP_PUBLIC_URL +"/Simone_Wujanz/Simone_Wujanz_Lampe.jpeg",
  process.env.REACT_APP_PUBLIC_URL +"/Simone_Wujanz/Simone_Wujanz_Halskette.jpeg",
  process.env.REACT_APP_PUBLIC_URL +"/Simone_Wujanz/Simone_Wujanz_Ohrringe.jpeg",
  process.env.REACT_APP_PUBLIC_URL +"/Simone_Wujanz/Simone_Wujanz_Mosaik.jpeg",
  process.env.REACT_APP_PUBLIC_URL +"/Simone_Wujanz/Simone_Wujanz_Postkarte.jpeg"],
  socialLinks: {
    instagram: "https://www.instagram.com/farbrausch_oberlausitz/",
    website: "https://www.etsy.com/shop/FarbrauschOL",
  },
  leader:'Inhaber von Farbrausch Oberlausitz',
  address:'Ringstraße 15, 02681 Schirgiswalde-Kirschau',
  email: 'monewu@web.de',
  fon:'+49 3592/544037',
  mobile:'+49 15204160818',
  vatID:'204 289 01240',
  descriptionInfo: 'Kleinunternehmer/in iSv § 19 UStG',
  detailedExpo:`Ich bin schon immer ein sehr kreativer Mensch gewesen und liebe es, Dinge selbst zu erschaffen. Dabei bin ich sehr vielseitig unterwegs: Acrylmalerei, grafische Techniken, Aktzeichnung, Collagetechniken, Handarbeiten, Mosaikarbeiten aus Fliesenbruch…und seit Anfang 2023 eine eigene Schmuckkollektion.

  Meine über 30jährige Lehrtätigkeit als Kunsterzieherin musste ich nach einer schweren Erkrankung aufgeben und habe mich nun beruflich neu orientiert.
  
  Mit Hilfe einer Zufallstechnik lasse ich Farbflächen entstehen, welche dann in die entsprechenden Rohlinge (Cabochons) eingearbeitet werden. So entstehen wunderschöne, einzigartige Schmuckstücke in kräftigen Farben. Jedes Stück ist ein Unikat und durch seine Farbigkeit und sein ungewöhnliches Design ein absoluter Hingucker. Ich liebe die Arbeit an meiner Kollektion, was man den Schmuckstücken einfach ansieht : )
  
  Auch kleine Gebrauchsgegenstände wie Lesezeichen, Weinstopper, Schlüsselanhänger oder Manschettenknöpfe stelle ich her, die sich wunderbar zum Verschenken eignen. Meine handgefertigten Karten zu allen Anlässen sind natürlich auch etwas ganz Besonderes.
  
  Ich freue mich sehr, ein Teil der Stadtwerkstatt Bautzen zu sein. Ich finde dieses Projekt einfach toll und sehr wichtig für unsere Region. Einheimische Künstler und Kreative können sich vor Ort präsentieren und ihre Werke an den Mann ( bzw. in meinem Fall vorwiegend an die Frau 😊) bringen. Also keine Massenprodukte „von der Stange“…sondern einzigartig, handgemacht und aus der Region. Dabei wünsche ich unserem Team viel Erfolg!`,
  paypal:false,
},
/*{index:18,
  id: 'Annett',
  name: "Annett Mucke",
  description: 'Ich bin Annett, Kreativkopf, Floristin & Nähmaus.',
  backgroundImage: process.env.REACT_APP_PUBLIC_URL +'/Annett_Mucke/Annett_Mucke_Babytuch.jpeg',
  portrait: process.env.REACT_APP_PUBLIC_URL +'/Annett_Mucke/Portrait_Annett_Mucke.jpeg',
  images: [ process.env.REACT_APP_PUBLIC_URL +"/Annett_Mucke/Annett_Mucke_Jacke.jpeg",
  process.env.REACT_APP_PUBLIC_URL +"/Annett_Mucke/Annett_Mucke_Cover.jpeg",
  process.env.REACT_APP_PUBLIC_URL +"/Annett_Mucke/Annett_Mucke_Armbänder.jpeg",
  process.env.REACT_APP_PUBLIC_URL +"/Annett_Mucke/Annett_Mucke_Deko.jpeg",
  process.env.REACT_APP_PUBLIC_URL +"/Annett_Mucke/Annett_Mucke_Babytuch.jpeg",
  process.env.REACT_APP_PUBLIC_URL +"/Annett_Mucke/Annett_Mucke_Kissen.jpeg"],
  socialLinks: {
    instagram: "https://www.instagram.com/annett_rosea/",
  },
  leader:'Inhaber von roseA',
  address:'Löbauerstr. 36, 02625 Bautzen',
  email: 'info@roseannett.de',
  mobile:'+49 152 22800514',
  vatID:'794 209 65816',
  descriptionInfo: 'Kleinunternehmer/in iSv § 19 UStG',
  detailedExpo:`Ich bin Annett, Kreativkopf, Floristin & Nähmaus.


Meine Passion: 	“aus einem Stück Stoff, mit Liebe & Handwerk - Etwas Einzigartiges zu entfesseln„


In meiner kleinen Nähstube zu Bautzen, bin ich und meine Familie zu Hause. Hier entstehen Allerlei Einzelstücke für Klein & Groß, sowie dekoratives für euer Zuhause.


Die Leidenschaft für das Nähen, durfte ich in einer ABM- Maßnahme 1995 bei einer gelernten Schneiderin von der Pike auf entdecken. Nachdem ich 2009 noch eine Floristen Ausbildung erfolgreich abgeschlossen habe, meine Kinder schon älter waren, habe ich mein Label „roseA“ 2017 ins Leben gerufen.


Die eingestaubte Nähmaschine wurde wieder ins Leben zurückgerufen & die floristischen Kenntnisse mit dem Nähen verbunden, welches ich bis heute zu sehr liebe und meine Kundschaft damit glücklich mache.


Mein Haupttätigkeit als Verkäuferin in einem Drogeriemarkt schätze ich sehr & mein leidenschaftliches Hobby ist dazu der perfekte Ausgleich.


Ganz Besonders freue ich mich, dass ich Euch in der StadtWerkstatt eine kleine feine Auswahl anbieten kann. Gern personalisiere ich auch nach euren Wünschen mit Liebe Einzelstücke, freue mich auf Eure Anfragen.
`,
  paypal:false,
},*/

{index:18,
  id: 'Marit',
  name: "Marit Schütze",
  description: 'Ich bin seit über 13 Jahren leidenschaftliche Künstlerin und fange in meinen Tierporträts die tiefe Verbindung zwischen Mensch und Tier ein.',
  backgroundImage: process.env.REACT_APP_PUBLIC_URL +'/Marit_Symalla/Vogel.jpg',
  portrait: process.env.REACT_APP_PUBLIC_URL +'/Marit_Symalla/Portrait_Marit_Symalla.jpg',
  images: [ process.env.REACT_APP_PUBLIC_URL +"/Marit_Symalla/schwalbeglaskombri.jpg",
  process.env.REACT_APP_PUBLIC_URL +"/Marit_Symalla/hundhandkopf.jpg",
  process.env.REACT_APP_PUBLIC_URL +"/Marit_Symalla/Marit.jpg",
  process.env.REACT_APP_PUBLIC_URL +"/Marit_Symalla/euledezimi.jpg",
  process.env.REACT_APP_PUBLIC_URL +"/Marit_Symalla/bienesohnekom.jpg",
  process.env.REACT_APP_PUBLIC_URL +"/Marit_Symalla/feldhasekombri.jpg"],
  socialLinks: {
    facebook:"https://www.facebook.com/artbymaritsymalla",
    instagram: "https://www.instagram.com/art_by_maritsymalla/",
    website:"https://www.marit-symalla.de/"
  },
  leader:'Art by Marit Symalla',
  address:'Alter Dorfplatz 5, 02692 Doberschau',
  email: ' info@marit-symalla.de',
  mobile:'+49 1749496857',
  vatID:'204/ 272/09114',
  descriptionInfo: 'Kleinunternehmer/in iSv § 19 UStG',
  detailedExpo:`Ich fertige seit über 13 Jahren professionell Tierportraits an. Zu zeichnen und malen fing ich schon in der Kindheit an, in meiner späteren Ausbildungszeit als Gestaltungstechnische Assistentin lernte ich noch einiges über die verschiedenen Stilrichtungen und Designs.


Zu dieser Zeit habe ich auch meine erste Auftragszeichnung für eine Bekannte erstellt. Diesen Moment als Sie das Kunstwerk in Händen hielt, werde ich nie vergessen. Für uns beide war es ein sehr emotionaler Moment. Genau das hat mich bestärkt meine Kunst weiter zu verfolgen. Ich habe über die Jahre an einigen Ausstellungen (Gruppen-, sowie Einzelausstellungen) teilgenommen und mich immer weiter entwickelt.


Zu meiner Kunst:

Meine Kunst hat sich in den letzten Jahren immer weiter verändert. Ich fange mit meinen Werken die Lebendigkeit und die Beziehung zwischen Mensch und Tier mit verschiedenen Materialien aus Acrylfarben, Kaffee, Tinte und Kohle ein. Mit ausdrucksstarken Pinselstrichen und dynamischen Kontrasten erzählen die Kunstwerke eine Geschichte verschiedener Welten. Die figürliche Darstellung bringt eine kraftvolle Emotionalität in jeden Raum, eine Einladung zum Innehalten und zur Reflexion über unsere Beziehung zur Natur.
`,
  paypal:false,
},
{index:21,
  id: 'Nicolle',
  name: "Nicolle Golloch",
  description: 'Ich heiße Nicolle. Inspiriert von der Natur fertige ich in liebevoller Handarbeit Schmuck, welcher deine Fantasie anregt.',
  backgroundImage: process.env.REACT_APP_PUBLIC_URL +'/Nicolle_Golloch/Armnband.jpeg',
  portrait: process.env.REACT_APP_PUBLIC_URL +'/Nicolle_Golloch/Portrait_Nicolle_Golloch.jpeg',
  images: [ process.env.REACT_APP_PUBLIC_URL +"/Nicolle_Golloch/Armnband.jpeg",
  process.env.REACT_APP_PUBLIC_URL +"/Nicolle_Golloch/gold.jpeg",
  process.env.REACT_APP_PUBLIC_URL +"/Nicolle_Golloch/Halskette.jpeg",
  process.env.REACT_APP_PUBLIC_URL +"/Nicolle_Golloch/Herzkette.jpeg",
  process.env.REACT_APP_PUBLIC_URL +"/Nicolle_Golloch/Holzanhänger.jpeg",
  process.env.REACT_APP_PUBLIC_URL +"/Nicolle_Golloch/Meersteine.jpeg"],
  socialLinks: {
    facebook:"https://www.facebook.com/profile.php?id=100084893017003&ref=xav_ig_profile_web",
    instagram: "https://www.instagram.com/modomio22/",
    
  },
  leader:'Inhaberin von Modomio',
  address:'Thomas-Mann-Str. 1, 01877 Bischofswerda',
  email: 'modomio22@gmx.de',
  
  vatID:'204/224/01319',
  descriptionInfo: 'Kleinunternehmer/in iSv § 19 UStG',
  detailedExpo:`Kreativ war ich eigentlich schon immer gern, egal ob beim Malen, Sticken, Häkeln, Basteln.


Vor einiger Zeit hatte ich (sehr spontan bei einer gemütlichen Tasse Kaffee) die Idee, mit Harz zu arbeiten. Ich wollte kleine Naturschätze in Harz gießen. Woher diese Idee kam...keine Ahnung. 
Also richtete ich mir im ehemaligen Kinderzimmer unserer bereits erwachsenen Tochter eine kleine Werkstatt ein. 
Im Garten,  beim Spaziergang mit dem Hund oder beim Wandern finde ich die schönsten Materialien für mein Hobby. Oder Freunde, Bekannte, Familie bringen mir ihre kleinen Naturfundsachen: "Schau mal was ich gefunden habe, brauchst du das?"

Damit fertige ich dann mit viel Liebe zum Detail Schmuck, welcher unsere wunderbare Natur wiederspiegelt.
Die Stadtwerkstatt inspiriert mich: die Idee des Miteinanders, die Art der Präsentation und die Bereitschaft der Künstler in Workshops gern ihr Wissen mit anderen zu teilen.
Ich freue mich sehr ein Teil des Stadtwerkstatt Teams sein zu können. 
`,
  paypal:false,
},
{index:24,
  id: 'Kathrin',
  name: "Kathrin Sturm",
  description: 'Mein Name ist Kathrin und ich töpfere die Keramikweiber, die ihr in der Stadtwerkstatt sehen könnt.',
  backgroundImage: process.env.REACT_APP_PUBLIC_URL +'/Kathrin_Sturm/Ton.jpeg',
  portrait: process.env.REACT_APP_PUBLIC_URL +'/Kathrin_Sturm/Portrait_Kathrin_Sturm.jpg',
  images: [ process.env.REACT_APP_PUBLIC_URL +"/Kathrin_Sturm/Ton.jpeg",
  process.env.REACT_APP_PUBLIC_URL +"/Kathrin_Sturm/Figur.jpeg",
  process.env.REACT_APP_PUBLIC_URL +"/Kathrin_Sturm/Tonfigur.jpeg",
  process.env.REACT_APP_PUBLIC_URL +"/Kathrin_Sturm/Spatz.jpeg",
  process.env.REACT_APP_PUBLIC_URL +"/Kathrin_Sturm/Prinzessin.jpeg",
  process.env.REACT_APP_PUBLIC_URL +"/Kathrin_Sturm/Tonvogel.jpeg"],
  socialLinks: {
    facebook:"https://www.facebook.com/kathrin.sturm.503",
    instagram: "https://www.instagram.com/kathrinsturm/",
    website:"https://keramikweib.de/"
  },
  leader:'Inhaberin von Keramikweib',
  address:'Techritzer Str. 1a, 02692 Grubschütz',
  email: 'kathrin@keramikweib.de',
  fon:'+49 3591-301451',
  descriptionInfo: 'Umsatzsteuer-Identifikationsnummer gemäß § 27 a Umsatzsteuergesetz: DE288142081',
  detailedExpo:`Beruflich schwinge ich den Pinsel und verschönere mit meinen Mitarbeitern der Malerfirma "Malermeister Sturm" Wände, Böden und Fassaden von Wohnungen und Häusern.
Ich lebe in einem Mehrgenerationenhaushalt im dörflichen Umfeld unserer schönen Stadt Bautzen.
Das Material Ton hat mich seit Jahren gepackt und so töpfere ich Figuren, total aus dem Leben gegriffen, gern auch nach individuellen Vorstellungen meiner Kunden.
Ich bin neu in der Stadtwerkstatt und freue mich auf diese neue und spannende Herausforderung.
`,
  paypal:false,
},
{index:27,
  id: 'Alex',
  name: "Alexander Schiebel",
  description: 'Ich bin Alex - der kreativer Kopf und Chef der ersten Bautzener Speiseeis-Manufaktur sowie Hersteller feinster Spirituosen wie Schiebels Absinthe & Liköre.',
  backgroundImage: process.env.REACT_APP_PUBLIC_URL +'/Alexander_Schiebel/flaschen.jpeg',
  portrait: process.env.REACT_APP_PUBLIC_URL +'/Alexander_Schiebel/Portrait_Alexander_Schiebel.jpeg',
  images: [ process.env.REACT_APP_PUBLIC_URL +"/Alexander_Schiebel/flaschen.jpeg",
  process.env.REACT_APP_PUBLIC_URL +"/Alexander_Schiebel/glas.jpeg",
  process.env.REACT_APP_PUBLIC_URL +"/Alexander_Schiebel/bild.jpeg",
  process.env.REACT_APP_PUBLIC_URL +"/Alexander_Schiebel/absinth.jpeg",
  process.env.REACT_APP_PUBLIC_URL +"/Alexander_Schiebel/kessel.jpeg",
  process.env.REACT_APP_PUBLIC_URL +"/Alexander_Schiebel/eierlikör.jpeg"],
  socialLinks: {
    facebook:"https://www.facebook.com/alexander.schiebel.9/",
    instagram: "https://www.instagram.com/eisdealer1974/",
    
  },
  leader:'Inhaber von Schiebels Eis& Torten Manufaktur ',
  address:'Seminarstraße 25, 02625 Bautzen',
  email: 'eisdealer@freenet.de',
  fon:'+49 359140727',
  vatID:'89/420/967/151',
  descriptionInfo: '',
  detailedExpo:`Mein Name ist Alexander Schiebel, und ich bin 50 Jahre alt. Seit fast 25 Jahren habe ich das Glück, als kreativer Kopf und Geschäftsführer der ersten Speiseeismanufaktur in Bautzen zu arbeiten. Zusammen mit meinem engagierten Team betreibe ich zudem die "Schiebels Eis und Torten Manufaktur", besser bekannt als „Eisdealer“, direkt aus Bautzen. Neben unseren beliebten Eis- und Tortenkreationen widme ich mich auch der Kunst des Likörs und bin stolz darauf, als Erfinder des ersten sächsischen Absinthe und Hersteller von Schiebels Absinthe & Liköre bekannt zu sein.


Besonders am Herzen liegt uns die Herstellung von Schiebels Eierlikör, der nach unserem eigenen Hausrezept in liebevoller Handarbeit gefertigt wird. Mit feinsten Zutaten und einem Hauch echter Madagaskar-Vanille gelingt es uns, diesem Likör seinen unverwechselbaren, köstlichen Geschmack zu verleihen. Jede Flasche wird mit Sorgfalt von Hand abgefüllt und verpackt – solange der Vorrat reicht.


Ein Highlight unserer Likörmanufaktur ist der außergewöhnliche Einhornpups Likör, hergestellt auf Basis von Eierlikör und verfeinert mit einem zarten Zitronen-Orangen-Aroma, ganz ohne künstliche Farb- oder Aromastoffe. Ebenso stolz sind wir auf unseren Eiersinthe Likör, eine einzigartige Kombination aus unserem hausgemachten Eierlikör und dem 1. Sächsischen Absinthe, die ein wahres Genusserlebnis bietet.


Ein besonderes Kapitel in unserer Geschichte ist der Schiebel Absinthe No. 4, eine limitierte Ausgabe von nur 116 Flaschen, die aus regional angebautem Wermut und anderen erlesenen Kräutern der Oberlausitz hergestellt wurde. Diese Edition steht für unser Engagement für traditionelle Absinthe-Herstellung und bleibt unser letzter Absinthe, um den Liebhabern dieser außergewöhnlichen Spirituose eine unvergessliche Reise in die Welt des Absinthes zu ermöglichen.


Unsere Philosophie ist klar: Handwerk, Qualität und der Verzicht auf künstliche Zusätze sind die Grundlage all unserer Produkte. Wir laden Sie ein, diese Leidenschaft in jeder unserer Kreationen zu entdecken.
`,
  paypal:false,
},
{index:30,
  id: 'Benjamin',
  name: "Benjamin Groß",
  description: 'Ich bin Benjamin, Winzer aus Leidenschaft und Gründer eines jungen Familienunternehmens, das den Weinbau in der Lausitz voranbringt und regionale Weine mit Charakter schafft.',
  backgroundImage: process.env.REACT_APP_PUBLIC_URL +'/Benjamin_Gross/rebe.jpeg',
  portrait: process.env.REACT_APP_PUBLIC_URL +'/Benjamin_Gross/Portrait_Benjamin_Gross.jpeg',
  images: [ process.env.REACT_APP_PUBLIC_URL +"/Benjamin_Gross/rebe.jpeg",
  process.env.REACT_APP_PUBLIC_URL +"/Benjamin_Gross/Flasche.jpeg",
  process.env.REACT_APP_PUBLIC_URL +"/Benjamin_Gross/Benjamin.jpeg",
  process.env.REACT_APP_PUBLIC_URL +"/Benjamin_Gross/weinauswahl.jpeg",
  process.env.REACT_APP_PUBLIC_URL +"/Benjamin_Gross/reben.jpeg",
  process.env.REACT_APP_PUBLIC_URL +"/Benjamin_Gross/reb.jpeg"],
  socialLinks: {
    wensite:"https://www.weinhausgross.de/",
    facebook:"https://www.facebook.com/weinhaus.gross",
    instagram: "https://www.instagram.com/weinhausgross?igshid=ify924y26j3o",
    
  },
  leader:'Inhaber von Weinhaus GROSS',
  address:'Dresdener Straße 33, 01877 Schmölln-Putzkau',
  email: 'weinhaus-gross@web.de',
  fon:'+49 3594/713896',
  mobile:"+49 157 344 18 116",
  vatID:'204/167/02323',
  descriptionInfo: '',
  detailedExpo:`Wir sind ein junges Familienunternehmen geführt von Benjamin Groß - Winzer aus Leidenschaft.


Unsere Idee besteht darin, Reben in der Lausitz anzubauen und diese anschließend zu vermarkten.  Der erste Erfolg kam mit unseren vier Jahreszeiten, die wir zusammen mit einem befreundeten Winzer, Jonas Korndörfer, von der Traube bis hin zum fertigen Wein produzieren und exklusiv für unser Weinhaus abfüllen lassen. Die ersten Trauben können wir bereits ernten und werden den Weinanbau in Putzkau weiter voranbringen.


Ich selbst mache schon Weinbau, seit ich 17 Jahre bin und habe mich sofort in den Beruf verliebt. Es ist und bleibt, für mich eine Faszination wie viele unterschiedliche Facetten ein Wein haben kann. Meine Ausbildung zum Winzer absolvierte ich in Freiburg im Breisgau und nach mehreren Stationen, in einigen anderen Weingüter und Sektkellereien, bin ich seit ca. 4 Jahren wieder in der Heimat. Meine Wurzeln sind hier und ich wollte schon immer den Weinbau hier etablieren, deshalb habe ich vor ca. 10 Jahren damit begonnen die ersten Reben zu pflanzen. 


Jedes Jahr ist für uns eine neue Herausforderung, aber ich bin sehr froh, wenn jemand unsere Passion mit uns teilt. Gerade in der Stadtwerkstatt, sieht man, wenn Kunst und Wein aufeinandertreffen und man damit zeigen kann, was alles in unserer Region machbar ist. 

`,
  paypal:false,
},
{index:33,
  id: 'Corinna',
  name: "Corinna Fischer",
  description: '',
  backgroundImage: process.env.REACT_APP_PUBLIC_URL +'/Benjamin_Gross/rebsfe.jpeg',
  portrait: process.env.REACT_APP_PUBLIC_URL +'/Workshop/Portrait_Corinna_Fischer.jpeg',
  images: [ process.env.REACT_APP_PUBLIC_URL +"/Benjamin_Gross/rebsfge.jpeg",
  process.env.REACT_APP_PUBLIC_URL +"/Benjamin_Gross/Flaschsfe.jpeg",
  process.env.REACT_APP_PUBLIC_URL +"/Benjamin_Gross/Benjsfamin.jpeg",
  process.env.REACT_APP_PUBLIC_URL +"/Benjamin_Gross/weinfsauswahl.jpeg",
  process.env.REACT_APP_PUBLIC_URL +"/Benjamin_Gross/refsben.jpeg",
  process.env.REACT_APP_PUBLIC_URL +"/Benjamin_Gross/refsb.jpeg"],
  socialLinks: {
   website:'https://www.corinnafischer.com/'
    
  },
  leader:'Anbieterin von eigener Naturkosmetik',
  address:'Lange Gasse 33, 02739 Kottmar OT Walddorf',
  email: 'fischer.corinna15@gmail.com',
  fon:'+49 3586 707 38 38',
  
  descriptionInfo: 'EXTERN',
  detailedExpo:`
`,
  paypal:false,
},
  ];
  export default TeamImpressumData;