import React, { useEffect, useState } from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/pagination';
import 'swiper/css/navigation';
import { Pagination, Navigation } from 'swiper/modules';
import debounce from 'debounce';
import SlideGrid from './SlidGrid';
import events from './events';
import { WorkshopDataProvider } from './WorkshopDataProvider';
import WorkshopDetails from './WorkshopDetails';
import { useNavigate, useParams } from 'react-router-dom';
import { AuthorDataProvider } from './AuthorDataProvider';

const MyCustomSwiper = () => {
  const navigate = useNavigate();
  const { id } = useParams();
  const [slides, setSlides] = useState(
    events
      .filter(event => new Date(event.start) >= new Date())
      .sort((a, b) => new Date(a.start) - new Date(b.start))
  );
  const [workshop, setWorkshop] = useState(id ? id : null);
  const [showModal, setShowModal] = useState(id ? true : false);
  const [size, setSize] = useState(() => resolveBreakpoint(window.innerWidth));

  function resolveBreakpoint(width) {
    if (width < 576) {
      return 1;
    } else if (width >= 576 && width < 768) {
      return 1;
    } else if (width >= 768 && width < 992) {
      return 2;
    } else if (width >= 992 && width < 1200) {
      return 2;
    } else if (width >= 1200 && width < 1440) {
      return 3;
    } else if (width >= 1440) {
      return 3;
    }
  }

  useEffect(() => {
    if (id != workshop && showModal === false) {
      setShowModal(true);
      setWorkshop(id);
    }
  }, [id, showModal, workshop]);

  useEffect(() => {
    const calcInnerWidth = debounce(function () {
      setSize(resolveBreakpoint(window.innerWidth));
    }, 200);
    window.addEventListener('resize', calcInnerWidth);
    return () => window.removeEventListener('resize', calcInnerWidth);
  }, []);

  const [index, setIndex] = useState(0);

  const handleSelect = (selectedIndex, e) => {
    setIndex(selectedIndex);
  };

  const appendSlide = () => {
    setSlides(prevSlides => [...prevSlides, `Slide ${prevSlides.length + 1}`]);
  };

  const prependSlide = () => {
    setSlides(prevSlides => [`Slide 0`, ...prevSlides]);
  };

  return (
    <>
      <div className='swiperUni'>
        <h2 className="text-center headline text-4xl text1 font-bold tracking-tight text-teal-700 sm:text-6xl" style={{ paddingBottom: '1%', paddingTop: '4%' }}>
          Aktuelle Workshops
        </h2>
        <Swiper
          slidesPerView={size}
          centeredSlides={true}
          spaceBetween={30}
          pagination={{
            clickable: true,
          }}
          navigation={true}
          initialSlide={1}
          modules={[Pagination, Navigation]}
          className="mySwiper"
        >
          {slides.map((slide, index) => (
            <SwiperSlide key={index} onClick={(e) => { setWorkshop(null); navigate('/workshops/' + slide.id); }}>
              <AuthorDataProvider>
                <SlideGrid
                  title={slide.title}
                  author={slide.author}
                  date={slide.start}
                  titleImageUrl={slide.titleImageUrl}
                  soldOut={slide.restAmount > 0 ? false : true}
                />
              </AuthorDataProvider>
            </SwiperSlide>
          ))}
        </Swiper>

        <p className="append-buttons">
          {/* Button-Event-Handler hier anpassen */}
        </p>
      </div>
      <AuthorDataProvider>
        <WorkshopDataProvider>
          {showModal && <WorkshopDetails open={showModal} setOpen={setShowModal} workshop={workshop} />}
        </WorkshopDataProvider>
      </AuthorDataProvider>
    </>
  );
};

export default MyCustomSwiper;
