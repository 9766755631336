import React, { useState, useEffect } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {  faTimes } from '@fortawesome/free-solid-svg-icons';
import { faInstagram } from '@fortawesome/free-brands-svg-icons';

const PopUp = () => {
    const [showPopup, setShowPopup] = useState(false);
  const [showIcon, setShowIcon] = useState(true);

  useEffect(() => {
    const timer = setTimeout(() => {
      setShowPopup(true);
      setTimeout(() => {
        setShowPopup(false);
      }, 5000); // Verschwindet nach 5 Sekunden
    }, 2000); // Popup erscheint nach 2 Sekunden

    return () => clearTimeout(timer);
  }, []);

  const handleMouseEnter = () => {
    setShowPopup(true);
  };

  const handleMouseLeave = () => {
    setShowPopup(false);
  };

  const handleClose = () => {
    setShowPopup(false);
    setShowIcon(false);
  };

  return (
    <div className="fixed top-1/2 right-0 transform -translate-y-1/2 flex items-center z-50">
    
      <div
        className={`bg-gradient-to-r z-0 from-yellow-400 via-pink-500 to-purple-600 text-white rounded-l-lg p-4 flex items-center transition-transform duration-300 ${
          showPopup ? 'translate-x-0' : 'translate-x-full'
        }`}
        onMouseLeave={handleMouseLeave}
      >
        <a href="https://www.instagram.com/stadt_werk_statt/" target="_blank" rel="noopener noreferrer" className="mr-2">
          Folge uns auf Instagram
        </a>
        <FontAwesomeIcon
          icon={faTimes}
          onClick={handleClose}
          className="w-6 h-6 cursor-pointer"
        />
      </div>
      {showIcon && (
        <div
          className="bg-gradient-to-r from-yellow-400 z-10 via-pink-500 to-purple-600 text-white rounded-l-lg p-4 flex items-center cursor-pointer"
          onMouseEnter={handleMouseEnter}
        >
          <FontAwesomeIcon icon={faInstagram} className="w-6 h-6" />
        </div>
      )}
    </div>
  );
};

export default PopUp;
