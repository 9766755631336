import { BrowserRouter, Route, Routes } from "react-router-dom";
import './App.css';
import './style.css';


import Contact from "./components/Contact";
import LandingPage from "./components/LandingPage";
import NavBar from "./components/navbar";

import Cardflipper from "./components/Cardflipper";
import MainPic from "./components/MainPic";
import CardExpander from "./components/cardexpander";
import SectionComment from "./components/SectionComment";

import CookieBanner from "./CookieBanner";
import Footer from "./components/Footer";
import Swiper from "./components/Swiper";
import MainTopicPart from "./components/MainTopicPart";
import MainTopicPartWorkshop from "./components/MainTopicPartWorkshop";
import WorkshopKalender from "./components/WorkshopKalender";
import Team from "./components/Team";
import WorkshopDetails from "./components/WorkshopDetails";
import { WorkshopDataProvider } from "./components/WorkshopDataProvider";
import { AuthorDataProvider } from "./components/AuthorDataProvider";
import Impressum from "./components/Impressum";
import Datenschutz from "./components/Datenschutz";
import { Helmet } from 'react-helmet';
import PopUp from "./components/PopUp";




function App() {
  
  return (
    <>
    <Helmet>
  <script type="application/ld+json">
    {`
      {
        "@context": "http://schema.org",
        "@type": "Article",
        "headline": "StadtWerkstatt Bautzen",
        "description": "Übersicht über die StadtWerkstatt Bautzen",
        "author": {
          "@type": "Person",
          "name": "Kati Schmidt"
        },
        "datePublished": "2024-02-01"
      }
    `}
  </script>
</Helmet>
    <BrowserRouter>
    <NavBar/>
            <Routes>
              <Route path="/" element={ 
              <>
              <PopUp/>
              <LandingPage/>  
              <MainPic/>
              <CardExpander/>
              <SectionComment/> 
              <Cardflipper/>
              <MainTopicPart/>
              <div className='swiperBack'>
              <Swiper/></div>
              <MainTopicPartWorkshop/> 
              <div className='backTopicPart'>
              <AuthorDataProvider><WorkshopDataProvider><Contact/></WorkshopDataProvider></AuthorDataProvider>
              </div>
              </>
              }> </Route>
              <Route path="/workshops" element={ 
              <>
              <Swiper/>
              <AuthorDataProvider>
              <WorkshopKalender/> 
              </AuthorDataProvider>
              <br></br>
              </> 
              }> </Route>

              <Route path="/workshops/:id" element={ 
              <>
              <Swiper/>
              <AuthorDataProvider>
              <WorkshopKalender/> 
              </AuthorDataProvider>              <br></br>
              </> 
              }> </Route>

              <Route path="/workshops/kalender/:idd" element={ 
              <>
              <Swiper/>
              <AuthorDataProvider>
              <WorkshopKalender/> 
              </AuthorDataProvider>              <br></br>
              </> 
              }> </Route>

              <Route path="/team/:id" element={ 
              <>
              <Team/>
              <br></br>
              </> 
              }> </Route>
              <Route path="/team/:id/info" element={ 
              <>
              <Team user='info'/>
              <br></br>
              </> 
              }> </Route>
              <Route path="/team" element={ 
              <>
              <Team/>
              <br></br>
              </> 
              }> </Route>
        
               <Route path="/kontakt" element={ 
              <>
              <AuthorDataProvider><WorkshopDataProvider><Contact/></WorkshopDataProvider></AuthorDataProvider>
              
              </> 
              }> </Route>

              <Route path="/kontakt/:id" element={ 
              <>
              <AuthorDataProvider><WorkshopDataProvider><Contact/></WorkshopDataProvider></AuthorDataProvider>
                <div style={{height:'450px'}}></div>
              </> 
              }> </Route>

              <Route path="/impressum" element={ 
              <>
              <Impressum/>
              
              </> 
              }> </Route>
               <Route path="/datenschutz" element={ 
              <>
              <Datenschutz/>
              
              </> 
              }> </Route>
            </Routes>
            <CookieBanner/>
            <Footer/>
        </BrowserRouter>
    </>

  );
}

export default App;
