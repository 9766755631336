import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faMapMarkedAlt } from '@fortawesome/free-solid-svg-icons';
import { faInstagram } from '@fortawesome/free-brands-svg-icons';
import { useNavigate } from 'react-router-dom';

const Footer = () => {
  const navigate = useNavigate();
  return (
    <footer className="footer  bg-black text-white p-6">
      <div className=" mx-auto">
        <div className="grid grid-cols-1 lg:grid-cols-3 gap-4 items-center text-center">
          <div className="flex flex-col items-center">
            <a onClick={(e)=>navigate("/impressum")} className="hover:text-gray-400 mb-2">Impressum</a>
            <a onClick={(e)=>navigate("/datenschutz")} className="hover:text-gray-400">Datenschutz</a>
          </div>
          <div>
            <p>© 2024 Stadtwerkstadt-Bautzen. Alle Rechte vorbehalten.</p>
          </div>
          <div className="flex flex-col items-center">
            <a href="https://www.google.de/search?sa=X&sca_esv=7a12701d0fbd3cd4&sca_upv=1&hl=de-DE&tbm=lcl&sxsrf=ACQVn0_T2YoGbhnz8IdwSDMcExDBvcgUqQ:1707854177754&q=StadtWerkstatt+Bautzen+Rezensionen&rflfq=1&num=20&stick=H4sIAAAAAAAAAONgkxIxNLKwNDG1MDE1NLc0NzE0NDM0M9jAyPiKUSm4JDGlJDy1KLu4JLGkRMEpsbSkKjVPISgVSBZn5uel5i1iJUIRABe3etRlAAAA&rldimm=12894584517974116160&ved=2ahUKEwiM8t7MjKmEAxXfh_0HHU_3CscQ9fQKegQIFRAF&biw=1920&bih=919&dpr=1#lkt=LocalPoiReviews" className="text-white hover:text-gray-400 mb-2">
              <FontAwesomeIcon icon={faMapMarkedAlt} size="lg" /> Google Maps
            </a>
            <a href="https://www.instagram.com/stadt_werk_statt/" className="text-white hover:text-gray-400">
              <FontAwesomeIcon icon={faInstagram} size="lg" /> Instagram
            </a>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
