import React, { useEffect, useState } from 'react';
import { Calendar, momentLocalizer } from 'react-big-calendar';
import moment from 'moment';
import 'moment/locale/de'; // Deutsche Lokalisierung für moment
import 'react-big-calendar/lib/css/react-big-calendar.css';
import events from './events'; // Importiere die Events
// Tailwind CSS importieren
import 'tailwindcss/tailwind.css';
import { useNavigate, useParams } from 'react-router-dom';
import WorkshopDetails from './WorkshopDetails';
import { WorkshopDataProvider } from './WorkshopDataProvider';
import { AuthorDataProvider, useAuthorData } from './AuthorDataProvider';

moment.locale('de'); // Setze Deutsch als Sprache für moment
const localizer = momentLocalizer(moment);


const EventComponent = ({ event }) => {
  const { currentAuthor, setAuthorByName } = useAuthorData();
  useEffect(() => {
    
      setAuthorByName(event.author);
    
  },[]);

    return (
      <div className="flex items-center">
        <img src={event.titleImageUrl} alt="Autor" className="w-8 h-8 rounded-full mr-2" /> {/* Autorenbild */}
        <span>{event.title}</span>
      </div>
    );
  };

const WorkshopKalender = () => {
  const navigate = useNavigate();
  const {idd}=useParams();
  const [workshop, setWorkshop] = useState(idd?idd:null);
  const [showModal, setShowModal] = useState(idd?true:false);

  useEffect(()=> {
    if(idd!=workshop&&showModal===false){
    setShowModal(true);
    setWorkshop(idd);
  }
},);

  const eventStyleGetter = (event, start, end, isSelected) => {
    var backgroundColor = event.hexColor;
    var style = {
      backgroundColor: backgroundColor,
      borderRadius: '5px',
      opacity: 0.8,
      color: 'white',
      border: '0px',
      display: 'block',
      margin:'0.1%'
    };
    return {
      style: style
    };
  };

  const handleSelectEvent = (event) => {
    setWorkshop('null');
    navigate('/workshops/kalender/'+event.id);
  };

  return (
    <>
    <div className="p-5">
      <Calendar
        localizer={localizer}
        events={events}
        startAccessor="start"
        endAccessor="end"
        style={{ height: 500 }}
        onSelectEvent={handleSelectEvent}
        eventPropGetter={eventStyleGetter}
        components={{
            event: EventComponent, // Verwende die benutzerdefinierte Event-Komponente
          }}
        messages={{
          next: "Nächster",
          previous: "Vorheriger",
          today: "Heute",
          month: "Monat",
          week: "Woche",
          day: "Tag",
          agenda: "Agenda",
          date: "Datum",
          time: "Zeit",
          event: "Workshop", // oder "Veranstaltung", je nach Kontext
          noEventsInRange: "Keine Workshops in diesem Zeitraum.",
          // Weitere Anpassungen hier, falls nötig
          
        }}
        
      />
    </div>
     <AuthorDataProvider>
     <WorkshopDataProvider>
     {showModal && <WorkshopDetails open={showModal} setOpen={setShowModal} workshop={workshop}/>}
     </WorkshopDataProvider>
    </AuthorDataProvider>
     </>
  );
};

export default WorkshopKalender;
