import React, { Fragment, useState, useEffect } from 'react';
import { Dialog, Disclosure, Popover, Transition } from '@headlessui/react'
import {
  ArrowPathIcon,
  Bars3Icon,
  ChartPieIcon,
  CursorArrowRaysIcon,
  FingerPrintIcon,
  SquaresPlusIcon,
  XMarkIcon,
  PaintBrushIcon,
  AcademicCapIcon,
  InformationCircleIcon
} from '@heroicons/react/24/outline'
import { ChevronDownIcon, HeartIcon, MapIcon, PhoneIcon, PlayCircleIcon, ShoppingBagIcon } from '@heroicons/react/20/solid'
import { useNavigate } from 'react-router-dom';

const products = [
  { name: 'Stadtwerkstadt', description: 'Alle Informationen über unsere Stadtwerkstatt in Bautzen', href: '/', icon: ShoppingBagIcon  },
  { name: 'Künstler', description: 'Hier erfahrt Ihr mehr über die Künsthandwerker/-innen', href: '/team', icon: PaintBrushIcon },
  { name: 'Workshops', description: 'Alle Informationen über das Workshopangebot', href: '/workshops', icon: AcademicCapIcon },
]
const callsToAction = [
  { name: 'Impressum', href: '/impressum', icon: InformationCircleIcon },
  { name: 'Kontakt', href: '/kontakt', icon: PhoneIcon },
]


export default function Example() {
  const navigate = useNavigate();
  const [mobileMenuOpen, setMobileMenuOpen] = useState(false)
  const [isScrolled, setIsScrolled] = useState(false);

  // Effekt zum Hinzufügen des Scroll-Listeners beim Mounten der Komponente
  useEffect(() => {
    const handleScroll = () => {
      // Setzt isScrolled auf true, wenn gescrollt wurde (z.B. über 10px)
      if (window.scrollY > 10) {
        setIsScrolled(true);
      } else {
        setIsScrolled(false);
      }
    };

    // Event-Listener hinzufügen
    window.addEventListener('scroll', handleScroll);

    // Cleanup-Funktion, um den Event-Listener zu entfernen
    return () => window.removeEventListener('scroll', handleScroll);
  }, []);


  return (
    <header className={`${isScrolled ? 'fixed bg-black/70 ' : 'relative bg-black shadow-lg'} top-0 left-0 z-50 w-full transition-all`}>
      <nav className="mx-auto flex max-w-7xl  items-center justify-between p-6 lg:px-8" aria-label="Global">
        {/* Logo zentral positioniert */}
        <div className="absolute left-1/2 lg:top-10 top-12 -translate-x-1/2 lg:-translate-y-10 -translate-y-10 z-100">
  <a onClick={(e)=>{navigate('/')}} className="block">
    {/* Runder Hintergrund direkt im Link-Container */}
    <div className="lg:flex lg:items-center lg:justify-center lg:bg-black/30 lg:backdrop-blur-md lg:rounded-full lg:p-2 lg:shadow-lg" > {/* Ersetzen Sie [Farbe] durch Ihre gewünschte Hintergrundfarbe, z.B. bg-blue-500 */}
      <img
        className="h-16 w-16 lg:h-24 lg:w-24 lg:rounded-full" // Größenanpassung des Logos
        src={`${process.env.REACT_APP_PUBLIC_URL}/LogoStadtWerkStadt.svg`}
        alt=""
      />
    </div>
  </a>
</div>
        
        {/* Mobile Menü Button */}
        <div className="lg:hidden">
          <button
            type="button"
            className={`${isScrolled ? ' bg-white/30 backdrop-blur-md hover:bg-gray-30 text-gray-500' : 'bg-gray-800 hover:bg-gray-500'} text-white -m-2.5 inline-flex items-center justify-center rounded-md p-2.5 `}
            onClick={() => setMobileMenuOpen(true)}
          >
            <span className="sr-only">Open main menu</span>
            <Bars3Icon className="h-6 w-6" aria-hidden="true" />
          </button>
        </div>
        
        {/* Links und Aktionen (versteckt in der mobilen Ansicht und neu geordnet um das Logo zentral zu halten) */}
        <Popover.Group className="hidden lg:flex lg:flex-1 lg:items-center lg:justify-between lg:order-1">
        
        <Popover className="relative">
        {({ close }) => (
          <>
            <Popover.Button className="flex items-center gap-x-1 text-sm font-semibold leading-6 text-white">
              Menü
              <ChevronDownIcon className="h-5 w-5 flex-none text-white" aria-hidden="true" />
            </Popover.Button>

            <Transition
              as={Fragment}
              enter="transition ease-out duration-200"
              enterFrom="opacity-50 translate-y-1"
              enterTo="opacity-80 translate-y-0"
              leave="transition ease-in duration-150"
              leaveFrom="opacity-80 translate-y-0"
              leaveTo="opacity-50 translate-y-1"
            >
              <Popover.Panel className="absolute -left-8 top-full  z-100 mt-3 w-screen max-w-md overflow-hidden rounded-3xl bg-black/50 backdrop-blur-md shadow-lg ring- ring-gray-900/5">
                <div className="p-4">
                  {products.map((item) => (
                    <div
                      key={item.name}
                      className="group relative flex items-center gap-x-6 rounded-lg p-4 text-sm leading-6 hover:bg-teal-900"
                    >
                      <div className="flex h-11 w-11 flex-none items-center justify-center rounded-lg bg-gray-50 group-hover:bg-white">
                        <item.icon className="h-6 w-6 text-gray-600 group-hover:text-teal-900" aria-hidden="true" />
                      </div>
                      <div className="flex-auto">
                        <a onClick={(e)=>{ e.preventDefault(); navigate(item.href); setMobileMenuOpen(false); close();}} className="block font-semibold text-white">
                          {item.name}
                          <span className="absolute inset-0" />
                        </a>
                        <p className="mt-1 text-gray-200">{item.description}</p>
                      </div>
                    </div>
                  ))}
                </div>
                <div className="grid grid-cols-2 divide-x divide-gray-900/5 bg-gray-50">
                  {callsToAction.map((item) => (
                    <a
                      key={item.name}
                      onClick={(e)=>{ e.preventDefault(); navigate(item.href); setMobileMenuOpen(false); close();}}
                      className="flex items-center justify-center gap-x-2.5 p-3 text-sm font-semibold leading-6 text-gray-900 hover:hover:bg-teal-300"
                    >
                      <item.icon className="h-5 w-5 flex-none text-gray-400" aria-hidden="true" />
                      {item.name}
                    </a>
                  ))}
                </div>
              </Popover.Panel>
            </Transition>
            </>
        )}
          </Popover> 
        </Popover.Group>
        
        {/* Platzhalter-Element, um das Zentrieren in der Desktop-Ansicht zu unterstützen */}
        <div className="hidden lg:flex lg:flex-1 lg:justify-end lg:order-3">
          {/* Dies könnte für zusätzliche Navigationslinks oder Aktionen genutzt werden, wenn nötig. */}
          <div className="hidden lg:flex lg:flex-1 lg:justify-end lg:order-3 space-x-4">
  <a href="https://www.instagram.com/stadt_werk_statt/" target="_blank" rel="noopener noreferrer" className="text-white hover:text-gray-300">
    <HeartIcon className="h-6 w-6" aria-hidden="true" /> {/* Beispiel für Instagram Icon */}
  </a>
  <a href="https://www.google.de/maps/place/StadtWerkstatt+Bautzen/@51.1811313,14.4295407,21z/data=!3m1!5s0x4708558f59f30a7b:0xc9a0d25e9201706e!4m6!3m5!1s0x470855db3fbc598f:0xb2f2c44cf8194740!8m2!3d51.181179!4d14.4292345!16s%2Fg%2F11ld9bf3kg?hl=de-DE&entry=ttu" target="_blank" rel="noopener noreferrer" className="text-white hover:text-gray-300">
    <MapIcon className="h-6 w-6" aria-hidden="true" /> {/* Beispiel für Google Maps Icon */}
  </a>
</div>
        </div>
      </nav>
      
      <Dialog as="div" className="lg:hidden" open={mobileMenuOpen} onClose={setMobileMenuOpen}>
        <Transition
          show={mobileMenuOpen}
          enter="transition ease-out duration-300"
          enterFrom="opacity-0 scale-95"
          enterTo="opacity-100 scale-100"
          leave="transition ease-in duration-200"
          leaveFrom="opacity-100 scale-100"
          leaveTo="opacity-0 scale-95"
        >
   <Dialog.Panel className="fixed inset-0 z-50 py-20 overflow-y-auto">
      <div className="fixed inset-0 bg-black bg-opacity-50" aria-hidden="true" onClick={() => setMobileMenuOpen(false)}></div>
      <div className="relative mx-auto my-5 max-w-sm rounded-lg bg-black/70 backdrop-blur-md p-4 shadow-lg">
        <div className="flex items-center justify-between">
          <Dialog.Title className="text-lg font-semibold text-white">Menü</Dialog.Title>
          <button
            type="button"
            className="rounded-md p-2 text-white hover:bg-teal-900"
            onClick={() => setMobileMenuOpen(false)}
          >
            <span className="sr-only">Menü schließen</span>
            <XMarkIcon className="h-6 w-6" aria-hidden="true" />
          </button>
        </div>
        <div className="mt-4 space-y-4">
          {products.map((item) => (
            <a
              key={item.name}
              onClick={(e)=>{navigate(item.href); setMobileMenuOpen(false);}}
              className="block p-2 rounded-md text-white hover:bg-teal-900 transition duration-150 ease-in-out"
            >
              <div className="flex items-center">
                <item.icon className="h-6 w-6 text-gray-400" aria-hidden="true" />
                <span className="ml-3">{item.name}</span>
              </div>
            </a>
          ))}
        </div>
        {/* Verbesserter Container für Kontakt und Impressum mit Abstand und Hover-Effekt */}
        <div className="mt-4 flex divide-x divide-gray-600 bg-teal-900 rounded-md overflow-hidden">
          {callsToAction.map((item, index) => (
            <a
              key={item.name}
              onClick={(e)=>{navigate(item.href); setMobileMenuOpen(false);}}
              className={`flex-1 flex items-center justify-center py-2 text-white transition duration-150 ease-in-out ${index === 0 ? 'pr-2' : 'pl-2'} hover:bg-gray-800/50`}
            >
              <item.icon className="h-5 w-5 text-gray-300 mr-2" aria-hidden="true" />
              <span>{item.name}</span>
            </a>
          ))}
        </div>
      </div>
    </Dialog.Panel>
  </Transition>
      </Dialog>
    </header>
  )
}
