import { Fragment, useEffect, useState } from "react";
import { Dialog, Transition } from "@headlessui/react";
import { XMarkIcon } from '@heroicons/react/24/outline'
import { StarIcon } from '@heroicons/react/20/solid'
import { faClock, faEnvelope, faHome, faIdCard, faMobileAlt, faPaintBrush, faPeopleGroup, faPhone, faTicket, faTicketAlt, faTicketSimple, faToolbox, faWrench } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useWorkshopData } from "./WorkshopDataProvider";
import { faIdeal } from "@fortawesome/free-brands-svg-icons";
import { useNavigate } from "react-router-dom";
import { useAuthorData } from "./AuthorDataProvider";
import {PayPalScriptProvider, PayPalButtons} from "@paypal/react-paypal-js";
import PayPalPayment from "./PayPalPayment";

export default function WorkshopDetails({ open, setOpen, workshop }) {
  const {currentWorkshop, setWorkshopByName } = useWorkshopData();
  const { currentAuthor, setAuthorByName } = useAuthorData();
  const [ buy, setBuy ] = useState(false);
 

  const navigate = useNavigate();
  // Setzt die Autorinformationen, wenn das Popup geöffnet wird und der Autorname sich ändert
  useEffect(() => {
    if (open && workshop) {
      setWorkshopByName(workshop);
    }
    if(currentWorkshop){
      setAuthorByName(currentWorkshop.author);
    }
  }, [open, currentWorkshop, workshop, setWorkshopByName]);
  
  if (!currentWorkshop) return null; // Oder ein Loading-Zustand oder eine Nachricht, wenn keine Daten vorhanden sind

  const formatDate = (dateString) => {
    const dateObj = new Date(dateString);
    const month = dateObj.toLocaleString('de-DE', { month: 'short' }).toUpperCase();
    const day = dateObj.getDate();
    return { month, day };
  };
  const { month, day } = formatDate(currentWorkshop?currentWorkshop.start:null);

  return (
    <Transition.Root show={open} as={Fragment}>
    <Dialog as="div" className="" onClose={setOpen} >
      <Transition.Child
        as={Fragment}
        enter="ease-out duration-300"
        enterFrom="opacity-0"
        enterTo="opacity-100"
        leave="ease-in duration-200"
        leaveFrom="opacity-100"
        leaveTo="opacity-0"
      >
        <div className="fixed inset-0 hidden z-10 bg-gray-500 bg-opacity-75 transition-opacity md:block" />
      </Transition.Child>

      <div className="fixed inset-0 z-10 w-screen overflow-y-auto">
        <div className="flex min-h-full items-stretch justify-center text-center md:items-center md:px-2 lg:px-4">
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0 translate-y-4 md:translate-y-0 md:scale-95"
            enterTo="opacity-100 translate-y-0 md:scale-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100 translate-y-0 md:scale-100"
            leaveTo="opacity-0 translate-y-4 md:translate-y-0 md:scale-95"
          >
            <Dialog.Panel style={{margin:'2%', marginTop:'90px'}} className="flex w-full transform text-left text-base transition md:my-8 md:max-w-2xl md:px-4 lg:max-w-4xl">
              <div className="relative flex w-full items-center overflow-hidden bg-white px-4 pb-8 pt-14 shadow-2xl sm:px-6 sm:pt-8 md:p-6 lg:p-8">
                <button
                  type="button"
                  className="absolute right-4 top-4 text-gray-400 hover:text-gray-500 sm:right-6 sm:top-8 md:right-6 md:top-6 lg:right-8 lg:top-8"
                  onClick={() => setOpen(false)}
                >
                  <span className="sr-only">Close</span>
                  <XMarkIcon className="h-6 w-6" aria-hidden="true" />
                </button>

                <div className="grid w-full grid-cols-1 items-start gap-x-6 gap-y-8 sm:grid-cols-12 lg:gap-x-8">
                <div className="calendarDetails">
          <div className="calendar-month">{month}</div>
          <div className="calendar-day">{day}</div>
        </div> {currentWorkshop.restAmount<1?<div className="sold-out-banner">Ausverkauft</div>:null}
                  <div  className="aspect-h-3 aspect-w-2 overflow-hidden rounded-lg bg-gray-100 sm:col-span-4 lg:col-span-5" >
                 
                    <img src={currentWorkshop.titleImageUrl} alt={currentWorkshop.titleImageUrl} className="object-cover object-center" />
                  </div>
                  <div className="sm:col-span-8 lg:col-span-7">
                    <h2 className="text-2xl font-bold text-gray-900 sm:pr-12">{currentWorkshop.title}</h2>

                    <section aria-labelledby="information-heading" className="mt-2">

                  <div className=" p-4">
                  <h2 className="text-xl text-teal-700 font-bold">Informationen</h2>
      <ul className="list-none mt-4">
      <li className="mt-2">
          {currentWorkshop.leader}
        </li>
        <li className="mt-2">
          <FontAwesomeIcon icon={faClock} className="text-teal-700"/> <a className="text-teal-700" style={{marginLeft:'0.5%', fontWeight:'bold'}}>Wann?</a> {currentWorkshop.start.toLocaleDateString()} {currentWorkshop.start.toLocaleTimeString().slice(0,5)} Uhr bis {currentWorkshop.end.toLocaleTimeString().slice(0,5)} Uhr
        </li>
        <li className="mt-2">
          <FontAwesomeIcon icon={faPeopleGroup} className="text-teal-700"/> <a className="text-teal-700" style={{marginLeft:'0.5%', fontWeight:'bold'}}>Restplätze:</a> {currentWorkshop.restAmount} 
        </li>
        <li className="mt-2">
          <FontAwesomeIcon icon={faTicket} className="text-teal-700"/> <a className="text-teal-700" style={{marginLeft:'0.5%', fontWeight:'bold'}}>Teilnehmerpreis:</a> {currentWorkshop.price} €
        </li>
        <li className="mt-2">
          <FontAwesomeIcon icon={faPaintBrush} className="text-teal-700"/> <a className="text-teal-700" style={{marginLeft:'0.5%', fontWeight:'bold'}}>Beschreibung:</a> {currentWorkshop.description.split('\n\n').map((paragraph, idx) => (
    <p key={idx}>{paragraph.split('\n').reduce((total, line) => [total, <br key={line} />, line])}</p>
  ))}
        </li>
        <br></br>
        <li className="mt-2 flex items-center justify-start"  onClick={(e)=>navigate("/team/"+currentAuthor.id+"/info")} style={{marginBottom:'3%'}}>
        <img src={currentAuthor ? currentAuthor.portrait : null} alt="Autor" style={{ width: '50px', height: '50px', borderRadius: '50%',  }} />
        <div className="flex-grow text-left" style={{marginLeft:'1%'}}>
    <p className="text-sm font-semibold">Verkäufer/in:</p>
    <p className="text-sm">{currentAuthor ? currentAuthor.name : ''}</p>
  </div>
 
        </li>
        
        <li className="mt-2">
                <a style={{marginRight:'2%'}}
                  onClick={(e)=>navigate("/kontakt/"+currentWorkshop.id)}
                  className="inline-block rounded-md border border-transparent bg-teal-700 px-8 py-3 text-center font-medium text-white hover:bg-teal-500"
                  
                >
                  Anfrage
                </a> 
                {currentWorkshop.restAmount>0&&(currentAuthor?currentAuthor.paypal:false)?(<>
                <a 
                  onClick={(e)=>setBuy(true)}
                  className="inline-block rounded-md border border-transparent bg-teal-400 px-8 py-3 text-center font-medium text-white hover:bg-teal-500"
                  
                >
                  Ticket kaufen
                </a> 
                {buy?
               <div style={{marginTop:'5%'}}>
                
                  <PayPalPayment/>
                </div>:null}</>):null}
                </li>
      </ul>
                  </div>
                  </section>
                </div> </div>
              </div>
            </Dialog.Panel>
          </Transition.Child>
        </div>
      </div>
    </Dialog>
  </Transition.Root>
  
  );
}
