import { useRef } from "react";
import UseIntersectionObserver from "./movingComment";

export default function SectionComment() {
    const ref = useRef(null); // Create a ref for the element to observe
    const ref2 = useRef(null);
  // Apply the custom hook to observe the element
  UseIntersectionObserver(ref, { threshold: 0.1 }); // You can adjust the options as needed
  UseIntersectionObserver(ref2, { threshold: 0.7 });
    return (
        <>
       
      <section className="relative isolate overflow-hidden z-0 bg-white px-6 py-24 sm:py-32 lg:px-8 commentSection">


<div
  className="absolute -top-52 left-1/2  -translate-x-1/2 transform-gpu blur-3xl sm:top-[-28rem] sm:ml-16 sm:translate-x-0 sm:transform-gpu"
  aria-hidden="true"
>
  <div
    className="aspect-[1097/845] w-[68.5625rem] bg-gradient-to-tr from-[#3c9d7a] to-[#1b5b4a] opacity-20"
    style={{
      clipPath:
        'polygon(74.1% 44.1%, 100% 61.6%, 97.5% 26.9%, 85.5% 0.1%, 80.7% 2%, 72.5% 32.5%, 60.2% 62.4%, 52.4% 68.1%, 47.5% 58.3%, 45.2% 34.5%, 27.5% 76.7%, 0.1% 64.9%, 17.9% 100%, 27.6% 76.8%, 76.1% 97.7%, 74.1% 44.1%)',
    }}
  />
</div>
<div
  className="absolute -top-52 left-1/2  -translate-x-1/4 transform-gpu blur-3xl sm:top-[-28rem] sm:ml-16 sm:translate-x-0 sm:transform-gpu"
  aria-hidden="true"
>
  <div
    className="aspect-[1097/845] w-[68.5625rem] bg-gradient-to-tr from-[#3c9d7a] to-[#1b5b4a] opacity-20"
    style={{
      clipPath:
        'polygon(74.1% 44.1%, 100% 61.6%, 97.5% 26.9%, 85.5% 0.1%, 80.7% 2%, 72.5% 32.5%, 60.2% 62.4%, 52.4% 68.1%, 47.5% 58.3%, 45.2% 34.5%, 27.5% 76.7%, 0.1% 64.9%, 17.9% 100%, 27.6% 76.8%, 76.1% 97.7%, 74.1% 44.1%)',
    }}
  />
</div>
        <div className="mx-auto max-w-2xl py-24 lg:max-w-7xl">
          <img className="mx-auto h-12" src="LogoStadtWerkStadlangform.svg" alt="" />
          <figure className="mt-5">
            <blockquote className="text-center text-xl font-semibold leading-8 text-gray-900 textsize sm:text-2xl sm:leading-9">
              <p ref={ref} className="hiddeni">
                “<a style={{fontWeight:'bold'}} >Herzlich Willkommen</a> in meiner Stadtwerkstatt in Bautzen, dem Herzen der Kreativität, wo Kunst und Handwerk auf einzigartige Weise zusammenfließen. Als Gründerin und treibende Kraft hinter „Ei-der-Daus“ habe ich diesen Ort geschaffen, um eine Gemeinschaft von Gleichgesinnten zu versammeln, die ihre Leidenschaft für das Kunsthandwerk teilen. In der Stadtwerkstatt bieten wir eine Vielzahl von Workshops an, die dazu einladen, neue Fähigkeiten zu entdecken und bestehende Talente zu vertiefen. </p>
                <p ref={ref2} className="hiddeni"><a style={{fontWeight:'bold'}} >Mein Ziel</a> ist es, einen inspirierenden Raum zu bieten, in dem Kreativität gedeihen kann, unterstützt durch den Austausch und die Zusammenarbeit talentierter Künstler und Kunsthandwerker aus unserer Region. Kommen Sie vorbei und werden Teil unserer lebendigen Künstlergemeinschaft <a style={{fontWeight:'bold', fontStyle:'italic'}} >– ein Ort, wo aus Ideen Wirklichkeit wird...</a>”
              </p>
            </blockquote>
            <figcaption className="mt-10">
              <img
                className="mx-auto h-10 w-10 rounded-full"
                src="./Kati_Schmidt/Portrait_Kati_Schmidt.jpeg"
                alt=""
              />
              <div className="mt-4 flex items-center justify-center textsize2 space-x-3 text-base">
                <div className="font-semibold text-gray-900">Kati Schmidt</div>
                <svg viewBox="0 0 2 2" width={3} height={3} aria-hidden="true" className="fill-gray-900">
                  <circle cx={1} cy={1} r={1} />
                </svg>
                <div className="text-gray-600">Gründerin der Stadtwerkstatt und Ei-der-Daus</div>
              </div>
            </figcaption>
          </figure>
        </div>
      </section>
      </>
    )
  }