import React from 'react';
import { PayPalButtons, PayPalScriptProvider } from '@paypal/react-paypal-js';
import axios from 'axios';

export default function PayPalPayment(clientID) {
  
  const createOrder = async (data, actions) => {
    try {
        return fetch("http://localhost:8080/api/orders/create", {
            method: "post",
          })
            .then((response) => response.json())
            .then((order) => order.id);
   
    } catch (error) {
      console.error('Order creation failed:', error);
      // Optionally, handle the error in the UI as well
    }
  };

  const onApprove = async (data, actions) => {
    try {
        return fetch(`http://localhost:8080/api/orders/${data.orderID}/capture`, {
            method: "post",
          })
            .then((response) => response.json())
            .then((orderData) => {
              // Successful capture! For dev/demo purposes:
              console.log(
                "Capture result",
                orderData,
                JSON.stringify(orderData, null, 2)
              );
              const transaction = orderData.purchase_units[0].payments.captures[0];
              alert(`Transaction ${transaction.status}: ${transaction.id}
    
                See console for all available details
              `);
            });
    } catch (error) {
      console.error('Order capture failed:', error);
      // Optionally, handle the error in the UI as well
    }
  };

  return (
    <PayPalScriptProvider options={{ clientId: "Ae3apaBzu1-4AtxIS2g0yCq_2lGmbtY_pTlTsPm71GMGbq2AQsSGKVmn1dlhSNJyo9WxMyZZBcAATAsh", intent: "capture",components: "buttons", currency: "EUR" }}>
    <PayPalButtons 
      createOrder={(data, actions) => createOrder(data, actions)}
      onApprove={(data, actions) => onApprove(data, actions)}
    /></PayPalScriptProvider>
  );
}
