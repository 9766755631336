import React, { createContext, useContext, useState } from 'react';
import TeamImpressumData from './TeamImpressumData';

const AuthorDataContext = createContext();

export const useAuthorData = () => useContext(AuthorDataContext);

export const AuthorDataProvider = ({ children }) => {
  const [authors, setAuthors] = useState(TeamImpressumData); // Initialisiert mit dem Autorenarray
  const [currentAuthor, setCurrentAuthor] = useState(null);

  const setAuthorByName = (name) => {
    const author = authors.find((author) => author.id === name);
    setCurrentAuthor(author);
  };

  return (
    <AuthorDataContext.Provider value={{ authors, currentAuthor, setAuthorByName }}>
      {children}
    </AuthorDataContext.Provider>
  );
};
