import { useEffect, useRef, useState } from "react";
import UseIntersectionObserver from "./movingComment";
import { useNavigate } from "react-router-dom";
import debounce from "debounce";

export default function MainTopicPartWorkshop() {
  const navigate = useNavigate();
  const ref = useRef(null);
  const ref2 = useRef(null);
  const [size, setSize] = useState(() => resolveBreakpoint(window.innerWidth));
// Apply the custom hook to observe the element
UseIntersectionObserver(ref, { threshold: 0.1 }); // You can adjust the options as needed
UseIntersectionObserver(ref2, { threshold: 0.5 });

function resolveBreakpoint( width){
  if ( width < 576 ) {
    return 2;
  } else if ( width >= 576 && width < 768 ) {
    return 3;
  } else if ( width >= 768 && width < 1100 ) {
    return 3;
  } else if ( width >= 1100 && width < 1200 ) {
    return 4;
  } else if ( width >= 1200 && width < 1440 ) {
    return 5;
  } else if ( width >= 1440 ) {
    return 5;
  }
}
useEffect(() => {
  const calcInnerWidth = debounce(function () {
    setSize(resolveBreakpoint(window.innerWidth));
  }, 200);
  window.addEventListener('resize', calcInnerWidth);
  return () => window.removeEventListener('resize', calcInnerWidth);
}, []);
    return (

      <div className="relative overflow-hidden backTopicPart">
         <div className="absolute inset-0 bg-gradient"></div>
         <div className="absolute inset-0 bg-blur-image"><div className="absolute inset-0 bg-blur-image">   <img
            src="./Stadtwerkstatt/Workshopraum_Stadtwerkstatt.jpg"
            alt="Blurred Background"
            className="h-full w-full object-cover blur-[2px] grayscale"/></div></div>
        <div className={size>3?("pb-80 unblurred-content pt-16 sm:pb-40 sm:pt-24 lg:pb-48 lg:pt-40"):""}>
          <div className="relative mx-auto max-w-7xl px-4 sm:static sm:px-6 lg:px-8">
            <div className="sm:max-w-lg">
              <h2 ref={ref} className="hiddeni text-4xl font-bold tracking-tight text-white sm:text-6xl" style={{marginTop:'10%'}}>
                Unser Workshop- und Veranstaltungsraum in Bautzen
              </h2>
              <p ref={ref2} className="hiddeni mt-4 text-xl text-white">
              Erleben Sie Kreativität und Inspiration pur in unserem Workshop- und Veranstaltungsraum in der Stadtwerkstatt Bautzen – der ideale Ort für Begegnungen, Lernen, kleine Feiern und künstlerischen Austausch.              </p>
              <div style={{paddingTop:'10%'}}></div>
              <a
                  onClick={(e)=>navigate("/workshops")}
                  className="inline-block rounded-md border border-transparent bg-teal-700 px-8 py-3 text-center font-medium text-white hover:bg-teal-500"
                >
                  Workshops
                </a>
                <a
                  onClick={(e)=>navigate("/kontakt")}
                  className="inline-block rounded-md border border-transparent bg-teal-950 px-8 py-3 text-center font-medium text-white hover:bg-teal-500"
                  style={{marginLeft:'1%'}}
                >
                  Anfrage
                </a>
            </div>
            <div>
            {size>3?(
              <div className="mt-10">
                {/* Decorative image grid */}
                <div
                  aria-hidden="true"
                  className="pointer-events-none lg:absolute lg:inset-y-0 lg:mx-auto lg:w-full lg:max-w-7xl"
                >
                  <div className="absolute transform sm:left-1/2 sm:top-0 sm:translate-x-8 lg:left-1/2 lg:top-1/2 lg:-translate-y-1/2 lg:translate-x-8">
                    <div className="flex items-center space-x-6 lg:space-x-8">
                      <div className="grid flex-shrink-0 grid-cols-1 gap-y-6 lg:gap-y-8">
                        <div className="h-64 w-44 overflow-hidden rounded-lg sm:opacity-0 lg:opacity-100">
                          <img
                            src="./Stadtwerkstatt/Fruehstueck_Stadtwerkstatt.jpeg"
                            alt=""
                            className="h-full w-full object-cover object-center"
                          />
                        </div>
                        <div className="h-64 w-44 overflow-hidden rounded-lg">
                          <img
                            src="./Stadtwerkstatt/Workshopraum_Tisch_Stadtwerkstatt.jpeg"
                            alt=""
                            className="h-full w-full object-cover object-center"
                          />
                        </div>
                      </div>
                      <div className="grid flex-shrink-0 grid-cols-1 gap-y-6 lg:gap-y-8 sm:opacity-0 lg:opacity-100">
                        <div className="h-64 w-44 overflow-hidden rounded-lg">
                          <img
                            src="./Stadtwerkstatt/Workshopraum_Stadtwerkstatt.jpg"
                            alt=""
                            className="h-full w-full object-cover object-center"
                          />
                        </div>
                        <div className="h-50 w-44 overflow-hidden rounded-lg">
                          <img
                            src="./Stadtwerkstatt/Willkommen_Stadtwerkstatt.jpeg"
                            alt=""
                            className="h-full w-full object-cover object-center"
                          />
                        </div>
                        <div className="h-64 w-44 overflow-hidden rounded-lg">
                          <img
                            src="./Stadtwerkstatt/Fruehstueck_Stadtwerkstatt.jpeg"
                            alt=""
                            className="h-full w-full object-cover object-center"
                          />
                        </div>
                      </div>
                      <div className="grid flex-shrink-0 grid-cols-1 gap-y-6 lg:gap-y-8">
                        <div className="h-64 w-44 overflow-hidden rounded-lg">
                          <img
                            src="./Stadtwerkstatt/Fruehstueck_Stadtwerkstatt.jpeg"
                            alt=""
                            className="h-full w-full object-cover object-center"
                          />
                        </div>
                        <div className="h-64 w-44 overflow-hidden rounded-lg">
                          <img
                            src="./Stadtwerkstatt/Workshopraum_Stadtwerkstatt.jpg"
                            alt=""
                            className="h-full w-full object-cover object-center"
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
  
               
              </div>):null}
            </div>
          </div>
          {size<4?(<div className="grid grid-cols-3 gap-2" style={{marginTop:'5%',marginBottom:'5%', marginLeft:'1%', marginRight:'1%'}}>
  <div className="overflow-hidden rounded-lg">
    <img
      src="./Stadtwerkstatt/Workshopraum_Tisch_Stadtwerkstatt.jpeg"// Stelle sicher, dass der Index für jedes Bild korrekt ist
      alt=""
      loading="lazy"
      className=" w-full object-cover object-center"
      style={{minHeight:size<3?'150px':'210px',maxHeight:size<3?'150px':'210px'}}
    />
  </div>
  <div className="overflow-hidden rounded-lg">
    <img
      src={'./Stadtwerkstatt/Workshopraum_Stadtwerkstatt.jpg'} // Ändere den Index entsprechend
      alt=""
      loading="lazy"
      className="w-full object-cover object-center"
      style={{minHeight:size<3?'150px':'210px',maxHeight:size<3?'150px':'210px'}}
    />
  </div>
  <div className="overflow-hidden rounded-lg">
    <img
      src="./Stadtwerkstatt/Fruehstueck_Stadtwerkstatt.jpeg" // Ändere den Index entsprechend
      alt=""
      loading="lazy"
      className=" w-full object-cover object-center"
      style={{minHeight:size<3?'150px':'210px',maxHeight:size<3?'150px':'210px'}}
    />
  </div>
</div>):null}

        </div>
      </div>
    )
  }