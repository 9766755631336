import React, { createContext, useContext, useState } from 'react';

import events from './events';

const WorkshopDataContext = createContext();

export const useWorkshopData = () => useContext(WorkshopDataContext);

export const WorkshopDataProvider = ({ children }) => {
  const [workshops, setWorkshops] = useState(events); // Initialisiert mit dem Autorenarray
  const [currentWorkshop, setCurrentWorkshop] = useState(null);

  const setWorkshopByName = (name) => {
    const workshop = workshops.find((workshop) => workshop.id === name);
    setCurrentWorkshop(workshop);
  };

  return (
    <WorkshopDataContext.Provider value={{ workshop: workshops, currentWorkshop: currentWorkshop, setWorkshopByName: setWorkshopByName }}>
      {children}
    </WorkshopDataContext.Provider>
  );
};
