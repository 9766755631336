import React, { useEffect } from 'react';
import { useAuthorData } from './AuthorDataProvider';


const SlideGrid = ({ title, author, date, titleImageUrl, soldOut }) => {
  const { currentAuthor, setAuthorByName } = useAuthorData();
  // Funktion, um den Monat in Kurzform und den Tag zu extrahieren
  const formatDate = (dateString) => {
    const dateObj = new Date(dateString);
    const month = dateObj.toLocaleString('de-DE', { month: 'short' }).toUpperCase();
    const day = dateObj.getDate();
    return { month, day };
  };

  const { month, day } = formatDate(date);
  useEffect(() => {
    if(author){
      setAuthorByName(author);
    }
  }, [author]);
  return (
    <div className="slide">
       <div className={`title-image ${soldOut ? 'grayscale' : ''}`}  style={{ backgroundImage: `linear-gradient(to top, rgba(0, 0, 0, 2) 10%, transparent), url(${titleImageUrl})` }}>
        <div className="calendar">
          <div className="calendar-month">{month}</div>
          <div className="calendar-day">{day}</div>
        </div>
        <div className="title">{title}</div>
        <img src={currentAuthor?currentAuthor.portrait:null} alt="Autor" className="author-image" />
       
      </div>
      {soldOut?<div className="sold-out-banner">Ausverkauft</div>:null}
    </div>
  );
  }
export default SlideGrid;
