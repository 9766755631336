import React, { useRef } from "react";
import UseIntersectionObserver from "./movingComment";
import { useNavigate } from "react-router-dom";
import TeamImpressumData from './TeamImpressumData';

export default function Cardflipper() {
    const navigate = useNavigate();
    const refs = useRef(TeamImpressumData.map(() => React.createRef())); // Create refs for each team member

    // Apply the custom hook to observe each element
    refs.current.forEach(ref => UseIntersectionObserver(ref, { threshold: 0.1 })); // You can adjust the options as needed

    return (
        <>
            <div className="flipper">
                <h2 className="headline text-4xl text1 font-bold tracking-tight text-white sm:text-6xl">Das Team</h2>
                <div className="cardflipper">
                    <div className="container">
                        {TeamImpressumData.map((member, index) => (member.id!='Corinna'?(
                            <div key={member.id} ref={refs.current[index]} className="card">
                                <div className="face face1">
                                    <div className="content">
                                        <img src={member.portrait} alt={`Portrait of ${member.name}`} />
                                        <h3>{member.name}</h3>
                                    </div>
                                </div>
                                <div className="face face2">
                                    <div onClick={() => navigate(`/team/${member.id}`)} className="content">
                                        <p>{member.description}</p>
                                    </div>
                                </div>
                            </div>
                        ):null))}
                    </div>
                </div>
            </div>
        </>
    );
}
