import { Fragment, useEffect } from "react";
import { useAuthorData } from "./AuthorDataProvider";
import { Dialog, Transition } from "@headlessui/react";
import { XMarkIcon } from '@heroicons/react/24/outline'
import { StarIcon } from '@heroicons/react/20/solid'
import { faEarth, faEnvelope, faHome, faIdCard, faMobileAlt, faPhone } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

export default function ImpressumPopUp({ open, setOpen, authorName }) {
  const { currentAuthor, setAuthorByName } = useAuthorData();

  // Setzt die Autorinformationen, wenn das Popup geöffnet wird und der Autorname sich ändert
  useEffect(() => {
    if (open && authorName) {
      setAuthorByName(authorName);
    }
  }, [open, authorName, setAuthorByName]);

  if (!currentAuthor) return null; // Oder ein Loading-Zustand oder eine Nachricht, wenn keine Daten vorhanden sind

  return (
    <Transition.Root show={open} as={Fragment}>
    <Dialog as="div" className="relative z-10" onClose={setOpen} >
      <Transition.Child
        as={Fragment}
        enter="ease-out duration-300"
        enterFrom="opacity-0"
        enterTo="opacity-100"
        leave="ease-in duration-200"
        leaveFrom="opacity-100"
        leaveTo="opacity-0"
      >
        <div className="fixed inset-0 hidden bg-gray-500 bg-opacity-75 transition-opacity md:block" />
      </Transition.Child>

      <div className="fixed inset-0 z-10 w-screen overflow-y-auto">
        <div className="flex min-h-full items-stretch justify-center text-center md:items-center md:px-2 lg:px-4">
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0 translate-y-4 md:translate-y-0 md:scale-95"
            enterTo="opacity-100 translate-y-0 md:scale-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100 translate-y-0 md:scale-100"
            leaveTo="opacity-0 translate-y-4 md:translate-y-0 md:scale-95"
          >
            <Dialog.Panel style={{margin:'2%', marginTop:'90px'}} className="flex w-full transform text-left text-base transition md:my-8 md:max-w-2xl md:px-4 lg:max-w-4xl">
              <div className="relative flex w-full items-center overflow-hidden bg-white px-4 pb-8 pt-14 shadow-2xl sm:px-6 sm:pt-8 md:p-6 lg:p-8">
                <button
                  type="button"
                  className="absolute right-4 top-4 text-gray-400 hover:text-gray-500 sm:right-6 sm:top-8 md:right-6 md:top-6 lg:right-8 lg:top-8"
                  onClick={() => setOpen(false)}
                >
                  <span className="sr-only">Close</span>
                  <XMarkIcon className="h-6 w-6" aria-hidden="true" />
                </button>

                <div className="grid w-full grid-cols-1 items-start gap-x-6 gap-y-8 sm:grid-cols-12 lg:gap-x-8">
                  <div  className="aspect-h-3 aspect-w-2 overflow-hidden rounded-lg bg-gray-100 sm:col-span-4 lg:col-span-5" >
                    <img src={currentAuthor.portrait} alt={currentAuthor.portrait} className="object-cover object-center" />
                  </div>
                  <div className="sm:col-span-8 lg:col-span-7">
                    <h2 className="text-2xl font-bold text-gray-900 sm:pr-12">{currentAuthor.name}</h2>

                    <section aria-labelledby="information-heading" className="mt-2">

                  <div className=" p-4">
                  <h2 className="text-xl font-bold">Informationen</h2>
      <ul className="list-none mt-4">
      <li className="mt-2">
          {currentAuthor.leader}
        </li>
        <li className="mt-2">
          <FontAwesomeIcon icon={faHome} /> <a style={{marginLeft:'0.5%', fontWeight:'bold'}}>Adresse:</a> {currentAuthor.address}
        </li>
        <li className="mt-2">
          <FontAwesomeIcon icon={faEnvelope} /> <a style={{marginLeft:'0.5%', fontWeight:'bold'}}>E-Mail:</a> {currentAuthor.email}
        </li>
        {currentAuthor.mobile?(
        <li className="mt-2">
          <FontAwesomeIcon icon={faMobileAlt} /> <a style={{marginLeft:'0.5%', fontWeight:'bold'}}>Mobil:</a> {currentAuthor.mobile}
        </li>):null}
        {currentAuthor.fon?(
        <li className="mt-2">
          <FontAwesomeIcon icon={faPhone} /> <a style={{marginLeft:'0.5%', fontWeight:'bold'}}>Telefon:</a> {currentAuthor.fon}
        </li>):null}
        {currentAuthor.vatID?(
        <li className="mt-2">
          <FontAwesomeIcon icon={faIdCard} /> <a style={{marginLeft:'0.5%', fontWeight:'bold'}}>Steuernummer:</a> {currentAuthor.vatID}
        </li>):null}
        <li className="mt-2">
           {currentAuthor.descriptionInfo}
        </li>
        {currentAuthor.socialLinks.website?(
        <li className="mt-2">
          <FontAwesomeIcon icon={faEarth} /> <a style={{marginLeft:'0.5%', fontWeight:'bold'}}>Webseite:</a> <a href={currentAuthor.socialLinks.website}>{currentAuthor.socialLinks.website}</a>
        </li>):null}
      </ul>
                  </div>
                  </section>
                </div> </div>
              </div>
            </Dialog.Panel>
          </Transition.Child>
        </div>
      </div>
    </Dialog>
  </Transition.Root>
  
  );
}
